import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {PurchaseOrder} from "./Logistics";

export class Manufacturing extends SubAPI {

    convertProducts(input: {
        output: {
            product: number
            qty: number;
        }
        input: {
            product: number
            qty: number;
        }[]
    }) {
        return this.fetcher.post<{}>("/api/manufacturing/convert-products", input)
    }

    listWeldShopItems(props: {
        offset: number;
    }) {
        return this.fetcher.get<Paginated<WeldShopPo>>("/api/manufacturing/weld-shop-pos", props)
    }

    listWeldShopPoItems(props: {
        purchaseOrder: number;
    }) {
        return this.fetcher.get<WeldShopPoItem[]>("/api/manufacturing/weld-shop-po/items", props)
    }

    weldShopBuildDoor(input: {
        purchaseOrderItem: number;
        outputProduct: number;
        inputProduct: number;
        materials: {
            product: number;
            qty: number;
        }[]
    }) {
        return this.fetcher.post("/api/manufacturing/weld-shop/build", input)
    }
}

export type WeldShopPo = PurchaseOrder & {
    qtyDone: number;
    qtyRemaining: number;
}

export type WeldShopPoItem = {
    id: number;
    core: string;
    elevation: string;
    elevationFile: number;
    finish: string;
    gauge: string;
    handing: string;
    label: string;
    material: string;
    seam: string;
    series: string;
    thickness: string;

    product: number;
    purchaseOrderItem: number;

    dimWidth: string;
    dimHeight: string;
    qty: number;
    qtyDone: number;

    preps: {
        description: string;
        custom?: number;
    }[];
}
