import React, {
    ForwardedRef,
    forwardRef,
    PropsWithChildren, RefAttributes,
    useCallback, useContext,
    useEffect, useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import {AutoSizer} from 'react-virtualized';
import {Alert, styled} from "@mui/material";
import {css} from "@emotion/css";
import {grey} from "@mui/material/colors";
import {Group, TID} from "./Table";
import {DragContext, DragProvider} from "./DragContext";
import {EventEmitter, sleep} from "nate-react-api-helpers";
import {useSyncedRef} from "../SyncedRef";
import {FilterContext} from "./Filter";
import {RunContext} from "./RunContext";

type InfiniteScrollWithGroupsProps<T> = {
    fixedHeader: any;
    header: any
    footer: any
    data: Group<T>[];
    blankElement?: any;
    fixedWidth: number;
    render: (input: T, index: number) => any;
    renderFixed: (input: T, index: number) => any;
    hasFixedData?: boolean
    renderGroupStart?: (group: Group<T>) => any;
    renderGroupEnd?: (group: Group<T>) => any;
    renderGroupStartFixed?: (group: Group<T>) => any;
    renderGroupEndFixed?: (group: Group<T>) => any;
    hasGroupStart?: (group: Group<T>) => boolean;
    hasGroupEnd?: (group: Group<T>) => boolean;
    noHorizontalScroll?: boolean;
    onDrag?(input: DragResult<T>): void
};

type TInfiniteScrollWithGroups = <T>(props: InfiniteScrollWithGroupsProps<T> & RefAttributes<ScrollInnerProps<any>>) => any;

const falseFx = () => false;

export const InfiniteScrollWithGroups: TInfiniteScrollWithGroups = forwardRef(function InfiniteScrollWithGroups<T extends TID>(props: InfiniteScrollWithGroupsProps<T>, ref: ForwardedRef<Scrollable>) {
    const {data, render, renderFixed, renderGroupStart, renderGroupEnd, renderGroupStartFixed, renderGroupEndFixed, onDrag, ...otherProps} = props;
    const hasGroupStart = props.hasGroupStart || falseFx;
    const hasGroupEnd = props.hasGroupEnd || falseFx;

    const transformedData = useMemo(() => {
        let list: LookupRow<T>[] = [];
        let rowIndex = 0;

        props.data.map((d, index) => {
            if(hasGroupStart(d)) {
                list.push({type: "group-start", data: d});
            }

            list.push(...d.rows.map((r, index) => {
                return {
                    type: "row" as "row",
                    data: r,
                    rowIndex: rowIndex++
                };
            }));

            if(hasGroupEnd(d)) {
                list.push({type: "group-end", data: d});
            }
            return null;
        })

        return list;
    }, [hasGroupEnd, hasGroupStart, props.data])

    const transformedRender = useCallback((input: LookupRow<T>, index: number) => {
        switch(input.type) {
            case "group-start":
                if(!renderGroupStart) return <div />
                return renderGroupStart(input.data);
            case "group-end":
                if(!renderGroupEnd) return <div />
                return renderGroupEnd(input.data);
            case "row":
                return render(input.data, input.rowIndex)
            default:
                return <div>INVALID RENDER_TYPE</div>
        }
    }, [render, renderGroupStart, renderGroupEnd])

    const hasFixedData = props.hasFixedData;
    const transformedRenderFixed = useCallback((input: LookupRow<T>, index: number) => {
        switch(input.type) {
            case "group-start":
                if(!renderGroupStartFixed || !hasFixedData) return <div />
                return renderGroupStartFixed(input.data);
            case "group-end":
                if(!renderGroupEndFixed || !hasFixedData) return <div />
                return renderGroupEndFixed(input.data);
            case "row":
                return renderFixed(input.data, input.rowIndex)
            default:
                return <div>INVALID RENDER_TYPE</div>
        }
    }, [renderFixed, renderGroupStartFixed, renderGroupEndFixed, hasFixedData])

    return (
        <InfiniteScroll<T>
            ref={ref}
            {...otherProps}
            render={transformedRender as any}
            renderFixed={transformedRenderFixed as any}
            data={transformedData as any}
            onDrag={onDrag || blankCallback}
        />
    )
}) as any;

const blankCallback = () => {}

type LookupRow<T> = {
    type: "group-start" | "group-end";
    data: Group<T>;
} | {
    type: "row";
    data: T;
    rowIndex: number;
}

const AutoSiz: any = AutoSizer;
type InfiniteScrollProps<T> = {
    fixedHeader: any;
    header: any
    footer: any
    blankElement?: any;
    data: LookupRow<T>[];
    fixedWidth: number;
    render: (input: LookupRow<T>, index: number) => any;
    renderFixed: (input: LookupRow<T>, index: number) => any;
    noHorizontalScroll?: boolean;
    onDrag(result: DragResult<T>): void;
}

type InfiniteScrollType = <T>(input: InfiniteScrollProps<T> & RefAttributes<Scrollable>) => any;

export const InfiniteScroll: InfiniteScrollType = forwardRef(InfiniteScrollIn) as any

function InfiniteScrollIn<T>(props: InfiniteScrollProps<T>, ref: ForwardedRef<Scrollable>) {

    const hScroller = useRef<HScroller>({
        body: null,
        header: null
    })

    return (
        <ErrorBoundary>
            <div style={{display: "flex", flexDirection: "column", flex: 1, width: "100%"}}>
                {props.header && <Header
                    fixedHeader={props.fixedHeader}
                    fixedWidth={props.fixedWidth}
                    header={props.header}
                    hScroller={hScroller.current}
                />}
                <div style={{flex: 1}}>
                    <AutoSiz>
                        {(size: any) => (<InfiniteScrollInner
                            ref={ref}
                            noHorizontalScroll={props.noHorizontalScroll}
                            hScroller={hScroller.current}
                            {...props}
                            {...size}
                        />)}
                    </AutoSiz>

                    {props.data.length === 0 ? props.blankElement ? props.blankElement : <NothingHere /> : null}
                </div>
                {props.footer && <div>{props.footer}</div>}
            </div>
        </ErrorBoundary>
    )
}

function NothingHere() {
    return (
        <div style={{textAlign: "center", fontSize: "0.8rem", paddingTop: 8}}>
            Nothing here
        </div>
    )
}

const HeaderScrollWrapperDiv = styled("div")(() => ({
    scrollbarWidth: "none",
    overflow: "auto",
    position: "relative",
    flex: 1,
    flexBasis: 0,

    "&::-webkit-scrollbar": {
        display: "none",
    }
}))

const Header: any = React.memo(function Header(props: {
    fixedWidth: number;
    fixedHeader: any;
    header: any;
    hScroller: HScroller;
}) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            overflow: "hidden",
            alignItems: "stretch",
        }}>
            <div style={{width: props.fixedWidth, flexShrink: 0, display: "flex"}}>
                {props.fixedHeader}
            </div>
            <HeaderScrollWrapperDiv ref={r => {
                props.hScroller.header = r;
            }} onScroll={e => {
                if(props.hScroller.body) {
                    const isSuspicious = e.currentTarget.scrollWidth === e.currentTarget.clientWidth || e.currentTarget.clientWidth < 10;
                    if(e.currentTarget.scrollLeft === 0 && isSuspicious) {
                        // do nothing because the children are probably re-rendering
                    } else {
                        props.hScroller.body.scrollLeft = e.currentTarget.scrollLeft;
                    }
                }
            }}>
                {props.header}
            </HeaderScrollWrapperDiv>
        </div>
    )
})

const maxRowCount = 50;
const defaultRowHeight = 20;

type HScroller = {
    body: HTMLDivElement|null;
    header: HTMLDivElement|null;
}

export type Scrollable = {
    getRect(): DOMRect | undefined
    scrollBy(deltaX: number, deltaY: number): void;
};

export type DragResult<T> = {
    value: T;
    before: T | null;
    idOrder: number[]
}

type ScrollInnerProps<T extends TID> = {
    data: LookupRow<T>[];
    render: (input: LookupRow<T>, index: number) => any;
    renderFixed: (input: LookupRow<T>, index: number) => any;
    height: number;
    width: number;
    fixedWidth: number;
    hScroller: HScroller;
    noHorizontalScroll?: boolean;

    onDrag(result: DragResult<T>): void
}
type TInfiniteScrollInner = <T extends TID>(props: ScrollInnerProps<T> & RefAttributes<Scrollable>) => any;

export function useDebugCreateDestroy(key: string) {
    useEffect(() => {
        console.log(key, "create")
        return () => console.log(key, "destroy")
    }, [key]);
}

const InfiniteScrollInner: TInfiniteScrollInner = React.memo(forwardRef(function InfiniteScrollInner<T extends TID>(props: ScrollInnerProps<T>, ref: ForwardedRef<Scrollable>) {
    const [dragging, setDragging] = useState<LookupRow<T>>();
    const dragRef = useRef<HTMLDivElement|null>(null)

    const [min, setMin] = useState(0);
    const [max, setMax] = useState(() => Math.min(maxRowCount, props.data.length));

    const minRef = useRef(min);
    minRef.current = min;

    const maxRef = useRef(max);
    maxRef.current = max;

    const [baseOffset, setBaseOffset] = useState(0);
    const heightsRef = useRef<number[][]>([]);
    const scrollHeightRef = useRef<HTMLDivElement|null>(null);
    const scrollHeightRef2 = useRef<HTMLDivElement|null>(null);
    const wrapperRef = useRef<HTMLDivElement|null>(null);
    const fixedRef = useRef<HTMLDivElement|null>(null);

    useEffect(() => {
        const expectedMinRange = {
            start: 0,
            end: Math.max(props.data.length - maxRowCount-1, 0),
        }

        const expectedMaxRange = {
            start: Math.min(maxRowCount, props.data.length),
            end: props.data.length,
        }

        if(minRef.current < expectedMinRange.start) {
            setMin(expectedMinRange.start);
            setMax(expectedMaxRange.start);
        } else if (minRef.current > expectedMinRange.end) {
            setMin(expectedMinRange.end);
            setMax(expectedMaxRange.end);
        } else if(maxRef.current < expectedMaxRange.start) {
            setMax(expectedMaxRange.start);
        } else if(maxRef.current > expectedMaxRange.end) {
            setMax(expectedMaxRange.end);
        }

        if(wrapperRef.current) {
            // don't snap to top when data is updated
            // stay in current context. (e.g. in the middle of a long list)
            //
            // wrapperRef.current.scrollTop = 0;
        }
    }, [props.data.length, props.render, props.renderFixed]);

    const updateScrollHeight = useCallback(() => {
        const el = scrollHeightRef.current;
        if(!el) return;

        const el2 = scrollHeightRef2.current;
        if(!el2) return;

        if(max < props.data.length-1) {
            el.style.height = "1000000px";
            el2.style.height = "1000000px";
        } else {
            let acc = baseOffset;

            for(let i = min; i <= max; i++) {
                const height = maxHeight(heightsRef.current[i]) || defaultRowHeight;
                acc += height;
            }

            // add 50 for buttons on the bottom (e.g. New Row)
            el2.style.height = (acc + 50) + "px";
            el.style.height = (acc + 50) + "px";
            
            const heights: number[] = [el.parentElement?.scrollHeight, el2.parentElement?.scrollHeight] as any;
            const height = Math.max(...heights);

            el2.style.height = height + "px";
            el.style.height = height + "px";
        }
    }, [max, props.data, baseOffset, min]);

    useEffect(() => {

        updateScrollHeight();
        setTimeout(() => updateScrollHeight(), 100);

    }, [updateScrollHeight])

    const scrollHandlerInner = useCallback((top: number) => {
        const moreToShow = max + 1 < props.data.length;
        const underScan = 300;

        if(top - baseOffset > underScan * 1.1 && moreToShow) {
            let acc = 0;
            let found = false;

            for(let i = 0; i < max; i++) {
                const height = maxHeight(heightsRef.current[i]) || defaultRowHeight;
                acc += height

                if(acc > top - underScan) {
                    setBaseOffset(acc);
                    setMin(i);
                    setMax(Math.min(props.data.length-1, i + maxRowCount));
                    found = true;
                    break;
                }
            }

            if(!found) {
                setBaseOffset(acc);
                setMin(Math.max(props.data.length - maxRowCount, 0));
                setMax(props.data.length - 1);
            }

        } else if (baseOffset > 0 && top - baseOffset < underScan * 0.9) {
            let acc = 0;

            for(let i = 0; i < max; i++) {
                const height = maxHeight(heightsRef.current[i]) || defaultRowHeight;
                acc += height;

                if(acc > top - underScan) {
                    if(i === 0) {
                        acc = 0;
                    }

                    setBaseOffset(acc);
                    setMin(i);
                    setMax(Math.min(props.data.length-1, i + maxRowCount));
                    break;
                }
            }
        }
    }, [baseOffset, max, props.data]);

    const scrollHandler = useCallback((e: any) => {
        if(fixedRef.current === e.currentTarget) {
            if(wrapperRef.current) {
                wrapperRef.current.scrollTop = e.currentTarget.scrollTop;
            }
        } else {
            if(fixedRef.current) {
                fixedRef.current.scrollTop = e.currentTarget.scrollTop;
            }
        }

        if(props.hScroller.header) {
            const isSuspicious = e.currentTarget.scrollWidth === e.currentTarget.clientWidth || e.currentTarget.clientWidth < 10;
            if(e.currentTarget.scrollLeft === 0 && isSuspicious) {
                // do nothing because the children are probably re-rendering
            } else {
                props.hScroller.header.scrollLeft = e.currentTarget.scrollLeft;
            }
        }

        const top = e.currentTarget.scrollTop;
        scrollHandlerInner(top)

    }, [props.hScroller.header, scrollHandlerInner]);

    const hScrollerRef = useSyncedRef(props.hScroller);

    useImperativeHandle(ref, () => ({
        getRect(): DOMRect | undefined {
          // @ts-ignore
          return hScrollerRef.current.body?.getBoundingClientRect();
        },
        scrollBy(deltaX: number, deltaY: number) {
            if(!hScrollerRef.current.body) return;
            hScrollerRef.current.body.scrollBy({
                top: deltaY,
                left: deltaX,
            })
        },
    }), [hScrollerRef])

    const filteredCtx = useContext(FilterContext);

    return (
        <DragProvider
            getScrollElement={() => {
                return hScrollerRef.current.body;
            }}
            onDrag={(index) => {
                const d = props.data[index]
                console.log("dragProvider", "onDrag", index, d);
                if(d.type !== "row") return;
                setDragging(d);
            }}
            onDragEnd={(i) => {
                if(!dragging || dragging.type !== "row") return;
                setDragging(undefined)

                let ids: number[] = [];
                filteredCtx.sortedRef.current.map(s => s.rows.map(r => ids.push(r.id)))

                ids = ids.filter(i => i !== dragging.data.id);

                const before = i < props.data.length ? props.data[i] : null
                let beforeData: T | null = null;

                if(before && before.type === "row") {
                    const i = ids.indexOf(before.data.id);
                    beforeData = before.data;
                    if(i === -1) {
                        ids.push(dragging.data.id)
                    } else {
                        ids.splice(i, 0, dragging.data.id);
                    }
                } else {
                    ids.push(dragging.data.id);
                }

                props.onDrag({
                    value: dragging.data,
                    before: beforeData,
                    idOrder: ids,
                })
            }}
            dragRef={dragRef}
        >
            <div style={{flex: 1, display: "flex",
                    flexDirection: "row",
                    alignItems: "stretch",
                    width: props.width
            }}>
                <div key="fixed" style={{
                    width: props.fixedWidth,
                    flexShrink: 0,
                    flexBasis: props.fixedWidth,
                    height: props.height,
                }}>
                    <WrapperDiv
                        style={{
                            height: props.height,
                            width: props.fixedWidth,
                            overflow: "hidden",
                        }}
                        onScroll={scrollHandler as any} ref={fixedRef}>
                        <div ref={scrollHeightRef2} style={{height: 1000000, width: 1}} />
                        <RenderBatch
                            baseOffset={baseOffset}
                            min={min}
                            max={max}
                            data={props.data}
                            render={props.renderFixed}
                            heightsRef={heightsRef}
                            heightIndex={0}
                        />
                    </WrapperDiv>
                </div>
                <div key="standard" style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "stretch",
                    flexDirection: "column",
                    overflow: "hidden",
                }}>
                    {dragging &&
                        <div ref={dragRef} style={{position: "fixed", zIndex: 1000, backgroundColor: "blue", minWidth: 100, minHeight: 10}}>
                            {props.render(dragging, -1)}
                        </div>}

                    <WrapperDiv
                        style={{
                            height: props.noHorizontalScroll ? props.height : props.height - 16,
                        }}
                        onScroll={scrollHandler as any}
                        ref={r => {
                            wrapperRef.current = r;
                            props.hScroller.body = r;
                        }}>
                            <div ref={scrollHeightRef} style={{height: 1000000}} />
                            <RenderBatch
                                baseOffset={baseOffset}
                                min={min}
                                max={max}
                                data={props.data}
                                render={props.render}
                                heightsRef={heightsRef}
                                heightIndex={1}
                            />
                    </WrapperDiv>
                    {props.noHorizontalScroll ? null : <HScrollbar hScroller={props.hScroller} />}
                </div>
            </div>
        </DragProvider>
    )
}) as any) as any;


export function HScrollbar(props: {
    hScroller: {body: HTMLDivElement|null}
}) {
    const innerRef = useRef<HTMLDivElement|null>(null);

    const [body, setBody] = useState<HTMLDivElement|null>(null);
    const bodyRef = useRef(body);
    bodyRef.current = body;

    const scroll = useCallback(() => {
        if(mouseXStart.current !== null) return; // active scrolling

        if(!innerRef.current) return;
        if(!bodyRef.current) return;
        const row = props.hScroller.body?.children[1];
        const rowWidth = row?.clientWidth;
        if(!rowWidth) return;

        const outerWidth = bodyRef.current.clientWidth;
        const scrollLeft = bodyRef.current.scrollLeft;
        const scrollLeftP = (scrollLeft / (rowWidth - outerWidth));

        const barParent = innerRef.current?.parentElement;
        if(!barParent) return;

        const barWidth = barParent.clientWidth * (outerWidth / rowWidth);
        const maxBarLeft = barParent.clientWidth - barWidth;

        innerRef.current.style.left = Math.round(scrollLeftP * maxBarLeft) + "px";
        innerRef.current.style.width = barWidth + "px";

    }, [props.hScroller]);

    // sync body with props.hScroller and update scroller (e.g. window-resize)
    useEffect(() => {
        const int = setInterval(() => {
            if(props.hScroller.body !== body) {
                setBody(props.hScroller.body);
            }

            scroll();
        }, body === null ? 100 : 800);

        return () => clearInterval(int);
    }, [props.hScroller, body, scroll])

    useEffect(() => {
        if(!body) return;

        scroll();

        body.addEventListener("scroll", scroll)
        return () => body.removeEventListener("scroll", scroll)
    }, [body, scroll]);

    const mouseXStart = useRef<number|null>(null);
    const scrollLeftStart = useRef<number|null>(null);

    const mouseMove = useCallback((e: MouseEvent) => {
        e.preventDefault();

        if(mouseXStart.current === null) return;
        if(scrollLeftStart.current === null) return;
        if(!innerRef.current) return;
        if(!bodyRef.current) return;

        const change = (e.clientX - mouseXStart.current);

        const barParent = innerRef.current.parentElement;
        if(!barParent) return;

        const barWidth = innerRef.current.clientWidth;
        const maxBarLeft = barParent.clientWidth - barWidth;

        const scrollbarLeft = clamp(scrollLeftStart.current + change, 0, maxBarLeft);
        innerRef.current.style.left = scrollbarLeft + "px";

        const percent = scrollbarLeft / maxBarLeft;
        bodyRef.current.scrollLeft = percent * bodyRef.current.scrollWidth;

    }, []);

    return (
        <div style={{paddingLeft: 4, paddingRight: 4}}>
            <div className={scrollWrapper}>
                <div className={moverTab} ref={innerRef}
                     onMouseDown={e => {
                         e.preventDefault();
                        mouseXStart.current = e.clientX;
                        scrollLeftStart.current = parseInt(e.currentTarget.style.left, 10);

                        document.body.addEventListener("mousemove", mouseMove)
                         const mouseOut = () => {
                             document.body.removeEventListener("mousemove", mouseMove)
                             document.body.removeEventListener("mouseup", mouseOut)
                             mouseXStart.current = null;
                         }

                        document.body.addEventListener("mouseup", mouseOut)
                        mouseMove(e as any);
                     }}
                     onMouseUp={e => {

                     }}></div>
            </div>
        </div>
    )
}

function clamp(value: number, min: number, max: number) {
    if(value < min) return min;
    if(value > max) return max;
    return value;
}

const scrollWrapper = css({
    width: "100%",
    height: 16,
    position: "relative",
});

const moverTab = css({
    backgroundColor: grey["200"],
    height: 14,
    top: 1,
    position: "relative",
    borderRadius: 4,
    cursor: "pointer",

    "&:active": {
        backgroundColor: grey["300"],
    },
    "&:hover": {
        backgroundColor: grey["300"],
    }
})

function maxHeight(height: number[]): number|undefined {
    if(!height) return undefined;
    if(height.length === 0) return undefined;
    if(height[0] === undefined) return height[1];
    if(height[1] === undefined) return height[0];
    if(height[0] > height[1]) return height[0];
    return height[1];
}

const WrapperDiv = styled("div")(() => ({
    // scrollbarWidth: "none",
    overflow: "auto",
    position: "relative",

    "&::-webkit-scrollbar": {
        display: "none",
    }
}))

type Callback<T> = (input: T) => void;

const RenderBatch: any = React.memo(function RenderBatch<T>(props: {
    baseOffset: number;
    min: number;
    max: number;
    data: T[];
    render: (input: T, index: number) => any;
    heightIndex: number;
    heightsRef: {current: number[][]}
}) {
    const {onDragInfo, newPositionRef} = useContext(DragContext);
    const dragInfo = EventEmitter.reactValue(onDragInfo);

    const [lessThan, setLessThan] = useState(props.min);
    const refs = useRef<(HTMLDivElement|null)[]>([]);

    useEffect(() => {

        for(let i = props.min; i < props.max; i++) {
            if(refs.current[i] === null) {
                setLessThan(i+1);
                return;
            }
        }

        if(props.max === 0) {
            setLessThan(props.max);
            return;
        }

        setLessThan(props.max+1);
    }, [props.max, props.min]);

    const lessThanRef = useRef(lessThan);
    lessThanRef.current = lessThan;

    const propsMaxRef = useRef(props.max);
    propsMaxRef.current = props.max;

    const propsMinRef = useRef(props.min);
    propsMinRef.current = props.min;
    const baseOffsetRef = useRef(props.baseOffset);
    baseOffsetRef.current = props.baseOffset;

    const dragInfoRef = useSyncedRef(dragInfo);

    const updateHeightsTm = useRef<any>(null);
    const heightsCtx = useRef<any>()

    const updateHeights = useCallback(async () => {
        if(heightsCtx.current) {
            heightsCtx.current.cancel();
            heightsCtx.current = null;
        }

        const ctx = new RunContext()
        heightsCtx.current = ctx;
        let start = Date.now();

        let acc = baseOffsetRef.current;
        const otherIndex = props.heightIndex === 0 ? 1 : 0;
        const dragInfo = dragInfoRef.current;
        let foundPosition = false;
        newPositionRef.current = lessThanRef.current;

        // batch style adjustments to prevent reflow warnings
        for(let i = propsMinRef.current; i < lessThanRef.current; i++) {
            let el = refs.current[i];
            if (!el) {
                if (i === dragInfo?.index) continue;
                break;
            }

            if(el.dataset["infHeightCalculated"]) continue

            el.style.minHeight = "";

            const c = el.children[0] as HTMLElement;
            if (c) {
                c.style.alignItems = "flex-start";
            }

            if(Date.now() - start > 50) {
                await sleep(0)
                if(ctx.cancelled) return;
                start = Date.now();
            }
        }

        // batch style adjustments to prevent reflow warnings
        const adjustStyleCallbacks: Callback<void>[] = [];

        // calculate style changes
        for(let i = propsMinRef.current; i < lessThanRef.current; i++) {

            let el = refs.current[i];
            if(!el) {
                if(i === dragInfo?.index) continue;
                break;
            }

            if(Date.now() - start > 50) {
                await sleep(0)
                if(ctx.cancelled) return;
                start = Date.now();
            }

            const e = el;
            const c = el.children[0] as HTMLElement;

            const box = el.getBoundingClientRect();
            let shift = false;

            if(dragInfo) {
                const overlapBottom = box.bottom > dragInfo.top && box.bottom < dragInfo.bottom;
                const below = box.top > dragInfo.bottom
                shift = overlapBottom || below;
            }

            if(shift && !foundPosition) {
                newPositionRef.current = i;
                foundPosition = true;
            }

            let myTop = acc;
            adjustStyleCallbacks.push(() => {
                if(shift && dragInfo) {
                    e.style.top = (myTop + dragInfo.height*2) + "px";
                } else {
                    e.style.top = myTop + "px";
                }
            })

            if(box.height === 74) {
                debugger;
            }

            if(!props.heightsRef.current[i]) props.heightsRef.current[i] = [];
            props.heightsRef.current[i][props.heightIndex] = box.height;

            const otherHeight = props.heightsRef.current[i][otherIndex];
            if(otherHeight !== undefined && otherHeight > el.clientHeight) {
                adjustStyleCallbacks.push(() => {
                    e.style.minHeight = otherHeight + "px";
                })
            }

            acc += maxHeight(props.heightsRef.current[i]) || defaultRowHeight;

            // skip height calculation
            if(el.dataset["infHeightCalculated"]) continue

            if(c) {
                adjustStyleCallbacks.push(() => {
                    // restore styling to original
                    c.style.alignItems = "";
                    e.dataset["infHeightCalculated"] = "true";
                });
            }
        }

        for(let i = 0; i < adjustStyleCallbacks.length; i++) {
            adjustStyleCallbacks[i]();

            if(Date.now() - start > 50) {
                await sleep(0)
                if(ctx.cancelled) return;
                start = Date.now();
            }
        }

    }, [props.heightIndex, props.heightsRef, dragInfoRef, newPositionRef]);

    const isFirst = useRef(true);
    useEffect(() => {
        // only run after dragInfo or heights changes
        if(isFirst.current) {
            isFirst.current = false;
            return;
        }

        updateHeights();
    }, [dragInfo, updateHeights])

    const onRef = useCallback((index: number, input: HTMLDivElement|null) => {
        if(!input) {
            refs.current[index] = null;
            return;
        }

        refs.current[index] = input;
        updateHeights()

        if(lessThanRef.current >= propsMaxRef.current+1) {
            if(updateHeightsTm.current) {
                clearTimeout(updateHeightsTm.current)
                updateHeightsTm.current = null;
            }

            updateHeightsTm.current = setTimeout(() => updateHeights(), 100);
            return;
        }

        setTimeout(() => setLessThan(lessThanRef.current+1));
    }, [updateHeights]);

    return (
        <>
            {range(props.min, lessThan)
                .filter(v => v !== dragInfo?.index)
                .map(r => <Row
                    key={r.toString()}
                    onRef={onRef}
                    dataInfHeightCalculated=""
                    render={props.render}
                    invalid={r >= props.data.length}
                    data={props.data[r]}
                    index={r} />)}
        </>
    )
})

function range(from: number, lessThan: number) {
    let n: number[] = [];

    for(let i = from; i < lessThan; i++) {
        n.push(i);
    }

    return n;
}

const Row: any = React.memo(function Row<T>(props: {
    onRef(index: number, value: HTMLDivElement|null): void;
    render(input: T, index: number): any;
    data: T;
    index: number;
    invalid: boolean;
    dataInfHeightCalculated: string
}) {
    if(props.invalid) return null;

    return (
        <RowWrapper ref={ref => {
            props.onRef(props.index, ref);
        }}
            dataInfHeightCalculated={props.dataInfHeightCalculated}
        >
            {props.render(props.data, props.index)}
        </RowWrapper>
    )
});

export const tableRow = "table-row";

const RowWrapper = forwardRef(function (props: PropsWithChildren<{
    dataInfHeightCalculated: string;
}>, ref: ForwardedRef<HTMLDivElement|null>) {
    const myRef = useRef<HTMLDivElement |null>(null);

    const r2 = myRef.current;
    if(r2) {
        r2.dataset["infHeightCalculated"] = props.dataInfHeightCalculated;
    }

    return (
        <div className={tableRow + " " + rowWrapperCss} ref={r => {
            myRef.current = r;
            if(r) {
                r.dataset["infHeightCalculated"] = props.dataInfHeightCalculated;
            }

            if(ref) {
                if (typeof ref === "function") {
                    ref(r);
                } else {
                    ref.current = r;
                }
            }
        }} data-inf-height-calculated={props.dataInfHeightCalculated}>
            {props.children}
        </div>
    )
});

const rowWrapperCss = css({
    position: "absolute",
    top: 1,
    left: 0,
    minWidth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
})

class ErrorBoundary extends React.Component<PropsWithChildren<any>, {error: string|null}> {
    constructor(props: any) {
        super(props);
        this.state = {error: null};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({
            error: error.name + " " + error.message
        })
    }

    render() {
        if(this.state.error) {
            return (
                <div style={{width: "100%"}}>
                    <Alert color="error">
                        There was an issue showing this list: {this.state.error}
                    </Alert>
                </div>
            )
        }

        return this.props.children;
    }
}