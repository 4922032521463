import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {
    HardwareGroup,
    HardwareGroupUpsert,
    HardwareGroupUpsertResponse, MergeCandidate, MergeGroup,
    PrepDetail, ProductReplacement,
} from "./QuoteHardware";

export class Hardware extends SubAPI {
    replaceProducts(input: {
        project: number;
        replacements: ProductReplacement[]
    }) {
        return this.fetcher.post("/api/hardware/replace-product", input)
    }

    reSequence(input: {
        project: number;
    }) {
        return this.fetcher.post("/api/hardware/re-sequence", input);
    }

    updateHardwareGroupLink(input: {
        opening: number;
        group: number;
        link: boolean
    }) {
        return this.fetcher.post("/api/hardware/edit-opening-link", input)
    }

    listDistributorDetails(input: {
        project: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<DistributorDetail>>("/api/hardware/distributor-detail", input)
    }

    updateDistributors(input: {
        project: number;
        changes: {
            product: number;
            overrideDistributor: number | null;
        }[]
    }) {
        return this.fetcher.post("/api/hardware/distributor-detail", input)
    }

    listWithContents(input: {
        project: number
        previewChangeset?: number;
    } | {
        groups: number[];
    }) {
        if("groups" in input) {
            return this.fetcher.get<HardwareGroupWithContent[]>("/api/hardware/with-content", {
                groups: input.groups.join(",")
            })
        }

        return this.fetcher.get<HardwareGroupWithContent[]>("/api/hardware/with-content", input)
    }

    list(input: {
        project: number;
        search?: string;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<HardwareGroup>>("/api/hardware", input)
    }

    listFinishes(input: {
        manufacturer: number;
        offset: number;
    }) {
        return this.fetcher.get<Paginated<Finish>>("/api/hardware/manufacturer-finishes", input)
    }

    upsert(input: HardwareGroupUpsert) {
        return this.fetcher.post<HardwareGroupUpsertResponse>("/api/hardware", input)
    }

    mergeCandidates(input: {
        project: number;
    }) {
        return this.fetcher.get<MergeCandidate[]>("/api/hardware/merge-candidates", input)
    }

    merge(input: {
        project: number;
        groups: MergeGroup[];
    }) {
        return this.fetcher.post("/api/hardware/merge", input)
    }
}

export type DistributorDetail = {
    id: number;
    product: number;
    name: string;
    productCode: string;
    manufacturer: number;
    manufacturerName: string;

    distributor?: number;
    distributorName?: string;

    distributorOverride?: number;
    distributorOverrideName?: string;
}

export type Finish = {id: number, finish: string; archived: boolean;}

export type HardwareGroupWithContent = {
    id: number;
    name: string;
    project: number;
    prepConfig?: PrepDetail[]
    prepConfigIsCustom?: boolean;
    flipDoubleActiveHanding: boolean;
    quoteAlternative?: number;

    doorName?: string;

    openings: HwOpening[];
    hardware: HardwareItem[];

    backup?: Omit<HardwareGroupWithContent, "backup"|"hardware"|"openings">;
}

export type HwOpening = {
    seqNumber: number;
    name: string,
    descriptiveName: string;
    handing: string;
    id: number,
    sessionChanged: boolean
    nominalHeight: string;
    nominalWidth: string;
    inactiveDoorWidth: string;
    screenElevation: string;
    screenElevationFile?: number;
    openingType: string;
}

export type HardwareItem = {
    id: number;
    product: number;
    manufacturer: number;
    manufacturerName: string;
    manufacturerShortName: string;
    name: string;
    productCode: string;
    finish: string;
    finishName: string;
    dimHeight: string;
    dimensions: string;
    dimensionFormula?: number;
    activeQty: number;
    inactiveQty: number;
    commonQty: number;
    isCustom: boolean;
    productIsAutoQtyHinge?: boolean;
    shortcode?: number;
    shortcodeItem?: number;
    shortcodeName?: string;
    shortcodeDescription?: string;
    customerSupplied: boolean
    defaultProvidedByOthers: boolean;

    hwPrepSingleActiveDoor?: string;
    hwPrepSingleActiveDoorCustomTemplate?: number;
    hwPrepSingleFrame?: string;
    hwPrepSingleFrameCustomTemplate?: number;
    hwPrepPairActiveDoor?: string;
    hwPrepPairActiveDoorCustomTemplate?: number;
    hwPrepPairBothActiveDoor?: string;
    hwPrepPairBothActiveDoorCustomTemplate?: number;
    hwPrepPairInactiveDoor?: string;
    hwPrepPairInactiveDoorCustomTemplate?: number;
    hwPrepPairFrame?: string;
    hwPrepPairFrameCustomTemplate?: number;

    calcTotalQty: number; // applies the active/inactive/common rules to the doors in the group
    calcByDimension: DimensionedQty

    note: string;
    archived: boolean;

    backup?: Omit<HardwareItem, "backup">;

    hingeActiveQtys?: number[];
    hingeInactiveQtys?: number[];

    overrideHingeActiveQty: number | null;
    overrideHingeInActiveQty: number | null;
}

export type DimensionedQty = {
    dimension: string
    qty: number;
}[] | null | undefined