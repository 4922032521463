import {SubAPI} from "./SubAPI";

export class Files extends SubAPI {
    downloadLink(id: number, type: "inline" | "attachment" = "attachment") {
        return "/api/file/download?id=" + id + "&type=" + type;
    }

    upload(input: {
        file: File;
        name?: string;
        convert2pdf?: boolean;
    }) {
        return this.fetcher.postFormData<{id: number; name: string}>("/api/file", {
            file: input.file,
            name: input.name || "",
            convert2pdf: input.convert2pdf ? "true" : "false"
        })
    }
}

export type FileInfo = {
    id: number;
    name: string;
}

// file.pdf => .pdf
export function ext(fileName: string): string {
    const dot = fileName.lastIndexOf(".")
    if (dot === -1) {
        return ""
    }

    return fileName.substring(dot)
}