import {useEffect, useState} from "react";
import {useUser} from "./Permission";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export function AutoRefreshPrompt() {
    const usr = useUser()
    const wantFtVersion = usr?.frontEndVersion;
    const [showNewVersion, setShowNewVersion] = useState<{
        wantFtVersion: string;
        actualFtVersion: string;
    }>();

    const [delayUntil, setDelayUntil] = useState<Date | null>(null);

    useEffect(() => {
        if(!delayUntil) return;

        const tm = setTimeout(() => {
            setDelayUntil(null);
        }, delayUntil.getTime() - Date.now());

        return () => clearTimeout(tm);
    }, [delayUntil]);

    useEffect(() => {
        if(!wantFtVersion) return;

        const actualFtVersion = Array.from(document.head.getElementsByTagName("meta")).find(m => m.name === "app_version")?.content;
        if(!actualFtVersion) return;

        if(delayUntil) return;

        if(wantFtVersion !== actualFtVersion) {
            setShowNewVersion({wantFtVersion, actualFtVersion})
        }
    }, [wantFtVersion, delayUntil]);

    if(showNewVersion && !delayUntil) {
        return <Dialog open>
            <DialogTitle>Handle has been updated</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please refresh your browser to get the latest updates. If you see this message again, please <a href="https://www.google.com/search?q=clear+browser+cache">clear your browser cache</a>.
                </DialogContentText>
                <div style={{height: 8}} />
                <DialogContentText>
                    If needed, you can dismiss this message and continue working for a few minutes. However, you may experience issues if you don't refresh your browser.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setDelayUntil(new Date(Date.now() + 2 * 60 * 1000));
                }}>Snooze for 2 minutes</Button>
                <Button onClick={() => {
                    window.location.reload();
                }}>Get Update</Button>
            </DialogActions>
        </Dialog>
    }

    return null;
}