import {
    createContext,
    MutableRefObject,
    PropsWithChildren,
    RefObject, useEffect,
    useMemo,
    useRef,
} from "react";
import {useSyncedRef} from "../SyncedRef";
import {EventEmitter} from "nate-react-api-helpers";

type DragInfo = {
    index: number;
    clientY: number;
    height: number;
    top: number;
    bottom: number;
}

export const DragContext = createContext({
    onDragInfo: new EventEmitter<DragInfo|null>(),
    newPositionRef: {current: 0} as MutableRefObject<number>,
    onMouseDown: (index: any, element: any, event: any) => {

    },
})

const lockX = true;

export function DragProvider(props: PropsWithChildren<{
    getScrollElement(): HTMLElement | null;
    onDrag(index: number): void;
    onDragEnd(newIndex: number): void;
    dragRef: RefObject<HTMLElement>;
}>) {
    const onDragRef = useSyncedRef(props.onDrag);
    const onDragEndRef = useSyncedRef(props.onDragEnd)
    const onDragInfo = useMemo(() => new EventEmitter<DragInfo|null>(), []);
    const newPositionRef = useRef(0);
    const {dragRef} = props;
    const getScrollElementRef = useSyncedRef(props.getScrollElement);

    const ctx = useMemo(() => ({
        onDragInfo: onDragInfo,
        newPositionRef: newPositionRef,
        onMouseDown: (index: any, element: any, event: any) => {
            newPositionRef.current = index;
            onDragRef.current(index);

            const scrollBox = getScrollElementRef.current()?.getBoundingClientRect();
            if(!scrollBox) {
                console.warn("unable to get scroll element box");
            }

            const scrollEl = getScrollElementRef.current();

            const mouseStart = {x: event.clientX, y: event.clientY};
            const pos = element.getBoundingClientRect();

            onDragInfo.emit({
                index: index,
                clientY: event.clientY,
                height: pos.height,
                top: pos.top,
                bottom: pos.bottom,
            })

            let delta = {
                x: 0,
                y: 0,
            };

            // try to update dragRef as soon as it's available
            let int = setInterval(() => {
                const el = dragRef.current;
                if(!el) return;

                cleanupInterval();
                updatePos();
            }, 100) as any;

            const cleanupInterval = () => {
                if(int) {
                    clearInterval(int);
                    int = 0;
                }
            }

            let autoScroll = 0;
            let autoScrollInt = 0;

            const cleanupAutoScroll = () => {
                if(autoScrollInt) {
                    clearInterval(autoScrollInt)
                    autoScrollInt = 0;
                }
            }

            const updatePos = () => {
                const el = dragRef.current;
                if(!el) return;

                cleanupInterval();

                const box = el.getBoundingClientRect();

                onDragInfo.emit({
                    index: index,
                    clientY: 0,
                    height: box.height,
                    top: box.top,
                    bottom: box.bottom,
                })

                el.style.top = (pos.y + delta.y) + "px";
                if(!lockX) {
                    el.style.left = (pos.x + delta.x) + "px";
                }

                autoScroll = 0;

                if(scrollBox && scrollEl) {
                    const dTop = pos.y + delta.y - scrollBox.top;
                    const dBottom = scrollBox.bottom - (pos.y + delta.y + pos.height);

                    if(dTop < -4) {
                        autoScroll = -32;
                    } else if (dTop < 4) {
                        autoScroll = -16;
                    } else if (dTop < 16) {
                        autoScroll = -8;
                    } else if(dBottom < -4) {
                        autoScroll = 32;
                    } else if(dBottom < 4) {
                        autoScroll = 16;
                    } else if(dBottom < 16) {
                        autoScroll = 8;
                    }

                    if(autoScroll === 0) {
                        cleanupAutoScroll();
                    } else if(!autoScrollInt) {
                        autoScrollInt = setInterval(() => {
                            scrollEl.scrollTop += autoScroll;
                        }, 100) as any;
                    }
                }
            }

            const mouseMove = (e: MouseEvent) => {
                delta = {
                    x: e.clientX - mouseStart.x,
                    y: e.clientY - mouseStart.y,
                };

                updatePos();
            }

            const mouseUp = (e: any) => {
                cleanupInterval();

                document.removeEventListener("mousemove", mouseMove)
                document.removeEventListener("mouseup", mouseUp)

                onDragEndRef.current(newPositionRef.current);
                onDragInfo.emit(null)
            }

            document.addEventListener("mousemove", mouseMove)
            document.addEventListener("mouseup", mouseUp)
        }
    }), [onDragInfo, newPositionRef, dragRef, onDragEndRef, onDragRef, getScrollElementRef]);

    useEffect(() => {
        // onDragInfo.subscribe((e) => console.log(e))
    }, [onDragInfo]);

    return (
        <DragContext.Provider value={ctx}>
            {props.children}
        </DragContext.Provider>
    )
}