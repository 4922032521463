import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";

export class Projects extends SubAPI {

    getProjectNumber(input: {
        project: number;
        alternative?: number;
    }) {
        return this.fetcher.get<string>("/api/project/project-number", input);
    }

    list(input: {
        search: string;
        offset: number;
        archived?: boolean;
        stage?: ProjectStage;
        company?: number;
        mode?: ProjectListMode
    }) {
        return this.fetcher.get<Paginated<Project>>("/api/projects/list", input)
    }

    upsert(input: {
        id?: number;
        name: string;
        quoteAlternative?: number;
        conversionAutoSequenceMode?: "default" | "per-floor";
    }) {
        return this.fetcher.post<{id: number}>("/api/projects", input)
    }

    get(id: number, alternative?: number) {
        return this.fetcher.get<Project>("/api/project", {
            id: id,
            alternative: alternative,
        })
    }

    getShopDrawingMeta(input: {project: number}) {
        return this.fetcher.get<DrawingMeta>("/api/projects/shop-drawing-meta", input)
    }

    updateShopDrawingMeta(input: DrawingMeta) {
        return this.fetcher.post("/api/projects/shop-drawing-meta", input)
    }

    getChecklist(input: {project: number}) {
        return this.fetcher.get<Checklist>("/api/project-checklist", input);
    }

    saveChecklist(input: {
        project: number;
        checklist: Checklist;
    }) {
        return this.fetcher.post("/api/project-checklist", input);
    }

    listAltAddresses(input: {
        project: number;
        excludeContractor?: boolean;
    }) {
        return this.fetcher.get<AltAddress[]>("/api/project/alt-addresses", input)
    }

    upsertAltAddress(input: Partial<AltAddress>) {
        return this.fetcher.post<AltAddress>("/api/project/alt-address", input)
    }
}

export type AltAddress = {
    id: number;
    project: number;
    name: string;

    addressCity: string;
    addressPostal: string;
    addressProvince: string;
    addressStreet: string;

    updatedAt: DateString;
    updatedBy: number;
    archived: boolean;

    contractorId: number | null;
}

export type Checklist = {
    [key: string]: ChecklistItem;
}

export type ChecklistItem = {
    value: string;
    comment: string;
}

type DrawingMeta = {
    project: number;
    managerLockEnabled: boolean;
    changesetEnabled: boolean;
    changesetSessionId?: number;
}

export type DateString = string;

export type Project = {
    id: number;
    name: string;
    nameAlias: string | null;
    createdAt: DateString;
    updatedAt: DateString;
    archived: boolean;

    addressStreet:   string;
    addressCity:     string;
    addressProvince: string;
    addressPostal:   string;

    contractor?: number;
    contractorName?: string;

    gcContact?: number;
    gcContactName?: string;
    gcContactEmail?: string;
    gcContactPhone?: string;

    client?:             number;
    clientName?:         string;

    pmContact?:      number;
    pmContactName?:  string;
    pmContactPhone?: string;
    pmContactEmail?: string;

    internalRep?:      number;
    internalRepName?:  string;
    internalRepTitle?: string;
    internalRepPhone?: string;

    projectManager?: number;
    pmTitle?: string;
    pmName?: string;
    pmPhone?: string;

    quoteAlternative?: number;

    unitSystem: UnitSystem;
    stage: ProjectStage;
    versionCode: string;

    backgroundJobError: null | string;
    backgroundJobErrorDetail: null | string;
}

export type UnitSystem = "metric" | "imperial";
export type ProjectStage = "quote" | "in-progress"
export type ProjectListMode = "to-pack"