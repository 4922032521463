import {useContext, useEffect, useMemo, useState} from "react";
import {Column, Group, lookupNestedProp, setNestedProp, TableContext, TID} from "./Table";
import {EditContext} from "./EditProvider";
import {useSnackbar} from "../Snackbar";
import {TablePersonalizeContext} from "./TablePersonalize";
import {selectMany} from "nate-react-api-helpers";
import {useSyncedRef} from "../SyncedRef";
import {UndoContext} from "./GlobalUndo";
import {getEditable, getEditKey} from "./TableEditCell";

const blankString = "<blank_string_nnnnnnnnnnnn>";

export function RangeActionProvider<T extends TID>(props: {
    columns: Column<T>[]
    onChange: (input: T) => Promise<any>
    data: Group<T>[];
}) {
    const {reload} = useContext(TableContext);
    const {onRange, onSelect, hasPopout, transition} = useContext(EditContext);
    const personalize = useContext(TablePersonalizeContext);
    const onChangeRef = useSyncedRef(props.onChange);
    const reloadRef = useSyncedRef(reload);
    const dataRef = useSyncedRef(selectMany(props.data, p => p.rows));

    const snack = useSnackbar();
    const snackRef = useSyncedRef(snack);
    const [hasRange, setHasRange] = useState(false)
    const [hasSelection, setHasSelection] = useState(false);

    useEffect(() => {
        const sub = onRange.subscribeAndFireLast(v => setHasRange(!!v));
        return () => sub.cancel()
    }, [onRange])

    useEffect(() => {
        const sub = onSelect.subscribeAndFireLast(v => setHasSelection(!!v && !v.editing));
        return () => sub.cancel()
    }, [onSelect])

    const getTableColumns = personalize.getTableColumns;
    const columns = useMemo(() => getTableColumns(props.columns), [props.columns, getTableColumns])
    const columnsRef = useSyncedRef(columns.columns)

    useEffect(() => {
        if(hasPopout) return;
        if (!hasRange && !hasSelection) return;

        const remove = async (e: KeyboardEvent) => {
            const isKeyCombination = (e.key === "Backspace" || e.key === "Delete");
            if(!isKeyCombination) return;

            const changer = onChangeRef.current;
            if(!changer) return;

            let range = onRange.lastValue;
            if(!range) {
                if(!onSelect.lastValue) return;

                range = {
                    ids: [onSelect.lastValue.id],
                    fromId: onSelect.lastValue.id,
                    toId: onSelect.lastValue.id,
                    toIndex: onSelect.lastValue.index,
                    fromIndex: onSelect.lastValue.index,
                }
            }

            let failed = false;

            try {
                const startIndex = Math.min(range.fromIndex, range.toIndex);
                const endIndex = Math.max(range.fromIndex, range.toIndex);

                const columns = columnsRef.current.filter((v: Column<T>, index) => index >= startIndex && index <= endIndex);

                if(columns.length === 1 && range.ids.length === 1) {
                    return // ignore b/c this is handled by single-cell editing.
                }

                // @ts-ignore
                let rows = dataRef.current.filter(v => range.ids.indexOf(v.id) !== -1)

                const batcher = new Batcher(5);

                batcher.onProgress(() => {
                    if(!failed && batcher.pending() > 0) {
                        snackRef.current.loading(`${batcher.pending()} items to update...`);
                    }
                });

                const promises = rows.map(p => {
                    const obj = Object.assign({}, p);

                    columns.map((column) => {
                        return setColumnValue(column, obj, p, "")
                    });

                    return batcher.add(() => changer(obj));
                })

                await Promise.all(promises);
                snackRef.current.success("Update complete!");

                reloadRef.current(true);
            } catch (err: any) {
                failed = true;
                snackRef.current.error(err.toString());
            }
        }

        document.body.addEventListener("keydown", remove);

        return () => {
            document.body.removeEventListener("keydown", remove);
        }
    }, [columnsRef, dataRef, hasSelection, hasRange, onChangeRef, onRange, onSelect, reloadRef, snackRef, hasPopout]);

    useEffect(() => {
        if(hasPopout) return;
        if(!hasRange && !hasSelection) return;

        const copy = (e: KeyboardEvent) => {
            const isKeyCombination = ((e.metaKey || e.ctrlKey) && e.key === "c");
            if(!isKeyCombination) return

            let range = onRange.lastValue;
            if(!range) {
                if(!onSelect.lastValue) return;

                range = {
                    ids: [onSelect.lastValue.id],
                    fromId: onSelect.lastValue.id,
                    toId: onSelect.lastValue.id,
                    toIndex: onSelect.lastValue.index,
                    fromIndex: onSelect.lastValue.index,
                }
            }

            // @ts-ignore
            let rows = dataRef.current.filter(v => range.ids.indexOf(v.id) !== -1);
            const minIndex = Math.min(range.fromIndex, range.toIndex);
            const maxIndex = Math.max(range.fromIndex, range.toIndex);

            const cols = columnsRef.current.filter((v: Column<T>, index) => index >= minIndex && index <= maxIndex)
            const tabSeparated = rows.map(r => {
                return cols.map(c => {
                    const editKey = getEditKey(c, r);
                    const editable = getEditable(c, r);
                    // @ts-ignore
                    if(editKey) return lookupNestedProp(r, editKey) as any;
                    if(editable?.type === "custom") return editable.copy(r);
                    if(editable?.type === "button") return "";
                    if(c.render) {
                        const result = c.render(r, c)
                        if(typeof result === "string") return result;
                    }

                    return ""
                }).map(v => v || blankString).join("\t")
            }).join("\n")

            e.preventDefault();

            navigator.clipboard.writeText(tabSeparated)
                .then(() => {
                    snackRef.current.success("Copied selection to clipboard");
                }, err => {
                    snackRef.current.error(err.toString());
                })
        }

        document.body.addEventListener("keydown", copy);

        return () => {
            document.body.removeEventListener("keydown", copy);
        }
    }, [columnsRef, hasSelection, dataRef, hasRange, onRange, onSelect, snackRef, hasPopout]);

    const undoCtx = useContext(UndoContext);
    const undoCtxPush = undoCtx.push;

    useEffect(() => {
        if(hasPopout) return;
        if(!hasSelection && !hasRange) return;

        const paste = async (e: KeyboardEvent) => {
            const isKeyCombination = ((e.metaKey || e.ctrlKey) && e.key === "v");
            if(!isKeyCombination) return;

            const changer = onChangeRef.current;
            if(!changer) return;

            e.preventDefault();

            let failed = false;

            try {
                const value = await navigator.clipboard.readText();
                let parts = value.trim().split("\n").map(v => v.replace(/\r$/g, ""));

                const range = onRange.lastValue
                const selection = onSelect.lastValue;

                let startId = 0;
                let startIndex = 0;
                let endIndex = 0;

                if(range) {
                    startId = range.ids[0];
                    startIndex = Math.min(range.fromIndex, range.toIndex);
                    endIndex = Math.max(range.fromIndex, range.toIndex);
                } else if(selection) {
                    startId = selection.id;
                    startIndex = selection.index;
                    endIndex = selection.index;
                }

                const columns = columnsRef.current.filter((v, index) => index >= startIndex);

                // @ts-ignore
                let dataIndex = dataRef.current.findIndex(v => v.id === startId);
                if(dataIndex === -1) {
                    throw new Error("Invalid start position");
                }

                const batcher = new Batcher(5);
                batcher.onProgress(() => {
                    if(!failed && batcher.pending() > 0) {
                        snackRef.current.loading(`${batcher.pending()} items to update...`);
                    }
                });

                const previous: T[] = [];

                let lastId = 0;
                const promises = parts.map(p => {
                    if(dataRef.current.length <= dataIndex) {
                        return Promise.resolve();
                    }

                    let cols = p.split("\t");
                    const src = dataRef.current[dataIndex]
                    const obj = Object.assign({}, src);

                    // save a backup for undo
                    previous.push(Object.assign({}, src));

                    lastId = dataRef.current[dataIndex].id;

                    cols.map((strValue, index) => {
                        if(index >= columns.length) return null;

                        if(strValue === blankString) {
                            strValue = "";
                        }

                        const column = columns[index];
                        setColumnValue(column, obj, src, strValue)
                    });

                    dataIndex++;
                    return batcher.add(() => changer(obj));
                })

                await Promise.all(promises);

                onSelect.emit({
                    id: lastId,
                    index: endIndex,
                    value: null,
                    editing: false,
                })
                transition("down")
                undoCtxPush(async () => {
                    const changer = onChangeRef.current;
                    if(!changer) return;

                    const batcher = new Batcher(5);
                    batcher.onProgress(() => {
                        if(batcher.pending() > 0) {
                            snackRef.current.loading(`${batcher.pending()} items to update...`);
                        }
                    });

                    const promises = previous.map(p => {
                        return batcher.add(() => changer(p));
                    })

                    await Promise.all(promises);
                    reloadRef.current(true);
                })

                snackRef.current.success("Update complete!");

                reloadRef.current(true);
            } catch (err: any) {
                failed = true;
                snackRef.current.error(err.toString());
            }
        }

        document.body.addEventListener("keydown", paste);

        return () => {
            document.body.removeEventListener("keydown", paste);
        }
    }, [hasSelection, hasRange, snackRef, onSelect, onRange, columnsRef, dataRef, onChangeRef, reloadRef, hasPopout, transition, undoCtxPush])

    return null;
}

type Callback = () => void

class Batcher {
    nParallel: number;
    active: number = 0;
    queue: Callback[] = [];
    progress: Callback = () => {}

    constructor(nParallel: number) {
        this.nParallel = nParallel;
    }

    add<T>(cb: () => Promise<T>) {
        return new Promise<T>((resolve, reject) => {
            this.queue.push(async () => {
                const update = () => {
                    this.active--;
                    this.pump();
                    this.progress();
                }

                try {
                    const r = await cb();
                    update();
                    resolve(r);
                } catch (e) {
                    update();
                    reject(e);
                }
            })

            this.pump();
        })
    }

    pump() {
        if(this.active >= this.nParallel) {
            return;
        }

        let first = this.queue.shift();
        if(!first) return;

        this.active++;
        first();
    }

    onProgress(cb: Callback) {
        this.progress = cb;
    }

    pending() {
        return this.active + this.queue.length;
    }
}

function setColumnValue(column: Column<any>, obj: any, src: any, strValue: string) {
    const editKey = getEditKey(column, obj);
    const editable = getEditable(column, obj)

    if(!editKey) {
        if(editable?.type === "button") {
            // do nothing
        } else if(editable?.type === "custom") {
            editable.paste(obj, strValue);
        } else if(column.editObj) {
            column.editObj(obj, src, strValue);
        }

        return null;
    }


    let value: any = strValue;
    // @ts-ignore
    if (typeof lookupNestedProp(obj, editKey) === "number") {
        value = parseFloat(strValue);
    } else if(typeof lookupNestedProp(obj, editKey) === "boolean") {
        value = strValue.toLowerCase() === "true";
    }

    if(!editKey) return null;
    // @ts-ignore
    setNestedProp(obj, editKey, value);
    return null;
}