import {SubAPI} from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";

export class ProjectJournal extends SubAPI {

    list(props: {
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<JournalEntry>>("/api/project-journal", props)
    }
}

export type JournalEntry = {
    id: number;
    project: number;
    createdAt: DateString;
    createdBy?: number;
    createdByName?: string;
} & ({
    type: "outbound-email",
    args: {
        subject: string;
        attachments: {File: number; Name: number}[];
    },
    emailQueue: number
} | {
    type: "delivery-complete",
    args: {
        name: string;
        route: number;

        signedFiles?: {File: number; Name: number}[];
        signedByName?: string;
        signedAt?: DateString;

        proofFiles?: {File: number; Name: number}[];
    },
    delivery: number;
    emailQueue: number | null;
})