import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";
import {TDiv10Type} from "../pages/project/shopdrawing/div10/Div10Tabs";
import {DescriptionInput} from "../pages/logistics/purchasing/toSource/SubmitButton";
import {InventoryKind} from "./Logistics";
import {ProgressCallback} from "nate-react-api-helpers";

export class Deliveries extends SubAPI {

    updateDelivery(props: Delivery) {
        return this.fetcher.post<Delivery>("/api/logistics/delivery", props)
    }

    signOff(props: Delivery) {
        return this.fetcher.post<Delivery>("/api/logistics/delivery/sign-off", props)
    }

    uploadProof(props: {
        delivery: number;
        file: File;
    }, onProgress: ProgressCallback) {

        return this.fetcher.postFormDataWithProgress("/api/logistics/delivery/proof/upload", {
            file: props.file,
            delivery: props.delivery.toString(),
        }, onProgress)
    }

    completeProof(props: {
        id: number;
    }) {
        return this.fetcher.post("/api/logistics/delivery/proof/complete", props)
    }

    updateDeliveryCustomerNote(props: {
        id: number;
        customerNote: string;
    }) {
        return this.fetcher.post("/api/logistics/delivery/customer-note", props)
    }

    updateDeliveryItem(props: DeliveryItem) {
        return this.fetcher.post<DeliveryItem>("/api/logistics/delivery/item", props)
    }

    getDelivery(input: {
        id: number;
    }) {
        return this.fetcher.get<Delivery>("/api/logistics/delivery", input)
    }

    listDeliveryItems(props: {
        offset?: number;
        delivery?: number;
        box?: number;
        limit?: number;
    }) {
        return this.fetcher.get<Paginated<DeliveryItem>>("/api/logistics/delivery/items", props)
    }

    listDeliveries(props: {
        offset?: number;
        search?: string;
        project?: number;
        toPick?: boolean;
        toDeliver?: boolean;
    }) {
        return this.fetcher.get<Paginated<Delivery>>("/api/logistics/deliveries", props)
    }

    partsToDeliver(props: {
        offset?: number;
        search?: string;
        project?: number | null;
        type?: "frame" |"hardware" | "door" | "frame-anchor" | "div10";
        div10Type?: TDiv10Type;
    }) {
        return this.fetcher.get<Paginated<ToDeliverItem>>("/api/logistics/delivery/pending-items-to-deliver", props)
    }

    updateBox(props: DeliveryBox & {
        setItems?: number[];
        addItems?: number[];
    }) {
        return this.fetcher.post("/api/logistics/delivery/box", props)
    }

    listBoxes(props: {
        delivery: number;
    }) {
        return this.fetcher.get<DeliveryBox[]>("/api/logistics/delivery/boxes", props)
    }
}

export type ToDeliverItem = {
    id: number;
    source: string;

    product: number;
    name:             string;
    productCode:      string;
    dimensions:       string;

    doorSeries?: string;
    doorMaterial?: string;
    doorFinish?: string;
    doorGauge?: string;
    doorElevation?: string;
    doorCore?: string;
    doorSeam?: string;
    doorHanding?: string;
    doorLabel?: string;
    doorThickness?: string;

    frameSeries?: string;
    frameMaterial?: string;
    frameGauge?: string;
    frameScreenElev?: string;
    frameProfile?: string;
    frameJambDepth?: string;
    frameConstruction?: string;
    frameHanding?: string;
    frameHardwarePreps?: string;
    frameLabel?: string;
    frameType?: string;
    frameAnchorName?: string;

    qty: number;

    manufacturer: number;
    manufacturerName: string;

    project: number;
    projectName: string;

    doorName?: string;

    inventoryRequestIds: number[];

    description?: string;
    dimWidth?: string;
    dimHeight?: string;
    dimLength?: string;
    finish?: string;
    div10Category?: string;
    div10CornerGuardFinish?: string;
    div10CornerGuardType?: string;
    div10Description?: string;
    div10LockerBaseTrim?: string;
    div10LockerCoating?: string;
    div10LockerEndPanels?: boolean;
    div10LockerSloped?: boolean;
    div10LockerTiers?: string;
    div10MailboxLoading?: string;
    div10MailboxMounting?: string;
    div10MailboxParcelUnits?: number;
    div10MailboxTenantUnits?: number;
    div10PartitionBracing?: string;
    div10Note?: string;
    div10PartitionCoating?: string;
    div10Room?: string;

    keyingDetail: undefined;
}

export type DeliveryItem = {
    id: number;

    source: string;
    delivery: number;

    deliveryBox?: number;
    deliveryBoxType?: string;
    deliveryBoxLabel?: string;

    product:          number;
    description:         string;
    code:               string;
    dimensions:       string;

    kind?: InventoryKind;
    details?: DescriptionInput & {
        div10Room: string | null
    };
    openings?: {id: number, name: string}[];

    qty: number;

    manufacturer: number;
    manufacturerName: string;

    inventoryRequests?: number[];

    archived: boolean;
    updatedAt: DateString;
    hasEnoughInWarehouse?: boolean;
}

export type Delivery = {
    id: number;
    project: number;
    projectName: string;
    status: string;
    pickByDate: DateString;
    pickedAt?: DateString;
    pickedByUser?: number;
    pickedByUserName?: string;
    archived: boolean;
    updatedAt: DateString;
    updatedByName: string;
    customerNote: string;

    deliveryReceiptBy: string | null;
    deliveryReceiptTimestamp: DateString | null;
    deliveryReceiptDoc: number | null;

    itemCount: number;
    hasEnoughInWarehouse: boolean;
    isMissingAnchors: boolean;
}

export type DeliveryBox = {
    id: number;
    delivery: number;
    label: string
    boxType: string;
    archived: boolean;
    itemCount: number;
}