import React, {
    createContext, MutableRefObject, PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo, useRef,
    useState
} from "react";
import {Alert, Button, Divider, Grid, LinearProgress, Menu, MenuItem, Popover, styled} from "@mui/material";
import {DragResult, InfiniteScrollWithGroups, Scrollable, tableRow} from "./InfiniteScroll";
import {FilterContext, FilterContextPrivate, FilterDetail, FilterProvider} from "./Filter";
import {blue, grey} from "@mui/material/colors";
import {
    EventEmitter,
    first,
    formatCents,
    parseCents,
    selectMany, sum
} from "nate-react-api-helpers";
import {CheckboxContext} from "../../pages/project/shopdrawing/release/Checkbox";
import {TablePersonalizeContext, TablePersonalizeProvider} from "./TablePersonalize";
import {api} from "../../api/API";
import {ColumnConfig, TableProjectConfig, TableProjectConfigResult} from "../../api/TableConfig";
import { css } from '@emotion/css'
import {EditCell, getEditable} from "./TableEditCell";
import {EditProvider, EditContext} from "./EditProvider";
import moment from "moment";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import clsx from "clsx";
import {useSyncedRef} from "../SyncedRef";
import {TableInsert} from "./TableInsert";
import {ImperialParser} from "../ImperialParser";
import {ImperialDistance} from "../ImperialDistance";
import {UndoContext} from "./GlobalUndo";
import {useSnackbar} from "../Snackbar";
import {UnitSystem} from "../../api/Projects";
import {MetricParser} from "../MetricParser";
import {MetricDistance} from "../MetricDistance";
import {RefreshRounded} from "@mui/icons-material";
import {ConfirmContext} from "../Confirm";
import {Calculator} from "./Calculator";
import InfoIcon from "@mui/icons-material/Info";

export type TID = {id: number} & object;

export function AdjustCol2<T extends TID>(input: Column<T>, partial: (old: Column<T>) => Partial<Column<T>>) {
    if(!input) return input;
    return Object.assign(input, partial(input));
}

export function AdjustCol<T extends TID>(input: Column<T>, partial: Partial<Column<T>>) {
    if(!input) return input;
    return Object.assign(input, partial);
}

export function ViewOnly<T extends TID>(input: Column<T>): Column<T> {
    return Object.assign(input, {
        editable: undefined,
        editKey: undefined,
    })
}

export function StringCol<T extends object>(name: string, key: NestedKeyOf<T>, width=100, opts?: {
    format?: "postal" | "email" | "phone";
}) {
    return {
        name: name,
        render: (o: T) => lookupNestedProp(o, key),
        editable: {
            type: "string",
            autoFormat: opts?.format,
        },
        editKey: key,
        width: width,
        filter: keyMatch(key),
        sort: sortString(key),
    } as any;
}

export function LongStringCol<T extends object>(name: string, key: NestedKeyOf<T>, width=100) {
    return {
        name: name,
        render: (o: T) => {
            return lookupNestedProp(o, key)
        },
        editable: {
            type: "long-string",
        },
        editKey: key,
        width: width,
        filter: keyMatch(key),
        sort: sortString(key),
    } as any;
}

export function ManufacturerCol<T extends object>(name: string, key: NestedKeyOf<T>, displayKey: NestedKeyOf<T>) {
    return {
        name: name,
        editable: {
            type: "lookup",
            displayKey: displayKey,
            getSelected: (input: T) => lookupNestedProp(input, key),
            options: async (search: string, input: T) => {
                const result = await api.companies.list({
                    search: search,
                    source: "div10"
                })

                return result.data.map(d => ({
                    value: d.id,
                    display: d.name,
                }))
            }
        },
        editKey: key,
        render: (d: T) => lookupNestedProp(d, displayKey),
        filter: keyMatch(displayKey),
        sort: sortString(displayKey),
    }
}

export function DistanceCol<T extends object>(name: string, unitSystem: UnitSystem, key: NestedKeyOf<T>, width=100) {
    return {
        name: name,
        render: (o: T) => lookupNestedProp(o, key),
        editable: {
            type: "string",
            parseValue: unitSystem === "metric" ? metricParser : imperialParser,
        },
        editObj: (dst: T, src: T, value: string) => {
            const parser = unitSystem === "metric" ? metricParser : imperialParser
            setNestedProp(dst, key, parser(value, value));
        },
        width: width,
        filter: keyMatch(key),
        sort: sortString(key),
        isChangedSinceBackup: (row: T) => {
            if("backup" in row) {
                // @ts-ignore
                return !!row.backup && lookupNestedProp(row.backup, key) !== lookupNestedProp(row, key)
            }

            return false;
        }
    } as any;
}

function formatNominalWidth<T extends object>(obj: T, nominalWidthKey: NestedKeyOf<T>, inactiveWidthKey: NestedKeyOf<T>): string {
    let nominal = lookupNestedProp(obj, nominalWidthKey)
    let inactive = lookupNestedProp(obj, inactiveWidthKey)
    if(inactive.trim() !== "") {
        return nominal + ", " + inactive;
    }

    return nominal;
}

const iconButtonClass = css({
    background:"none",
    border: "none",
    fontSize: "1rem",
    cursor: "pointer",
    height: "1.1rem",
    width: "1.1rem",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",

    "&:hover": {
        background: "rgba(0,0,0,0.09)",
    }
})

export function SeqNumberEl(props: {
    tableName: string;
    onReSequence(idsInOrder: number[]): Promise<any>
}) {

    const confirm = useContext(ConfirmContext);
    const reload = useTableRefresher(props.tableName);
    const filter = useContext(FilterContext);
    const snack = useSnackbar()

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <div>Seq #</div>
            <div style={{width: 10}} />
            <button className={iconButtonClass} onClick={async e => {
                e.stopPropagation();
                try {
                    await confirm.confirm("Are you sure you want to reassign sequence numbers based on the current sort order?")
                } catch (e) {
                    return
                }

                snack.loading();

                const order: number[] = [];
                filter.sortedRef.current.map(g => g.rows.map(r => order.push(r.id)));

                try {
                    await props.onReSequence(order);
                    snack.success("Updated");
                    reload();
                } catch (e: any) {
                    snack.error(e.message)
                }

            }}>
                <RefreshRounded fontSize="inherit" />
            </button>
        </div>
    )
}

export function SeqNumber<T extends object>(key: NestedKeyOf<T>, tableName: string, onReSequence: (idsInOrder: number[]) => Promise<any>) {
    const obj = ViewOnly(StringCol("Seq #", key))
    Object.assign(obj, {
        renderName: () => {
            return <SeqNumberEl tableName={tableName} onReSequence={onReSequence} />
        }
    })
    return obj;
}

export function NominalWidth<T extends object & {backup?: any}>(name: string, sys: UnitSystem, nominalWidthKey: NestedKeyOf<T>, inactiveWidthKey: NestedKeyOf<T>, width=100) {
    const parser = sys === "metric" ? metricParser: imperialParser;
    return {
        name: name,
        render: (o: T) => formatNominalWidth(o, nominalWidthKey, inactiveWidthKey),
        isChangedSinceBackup: (o: T) => {
            if(!o.backup) return false;

            if(lookupNestedProp(o, nominalWidthKey) !== lookupNestedProp(o.backup, nominalWidthKey)) {
                return true;
            }

            if(lookupNestedProp(o, inactiveWidthKey) !== lookupNestedProp(o.backup, inactiveWidthKey)) {
                return true;
            }

            return false;
        },
        editable: {
            type: "string",
        },
        editObj: (dst: T, src: T, value: string) => {
            const {nominal, inactive} = parseNominalWidth(value, parser)

            setNestedProp(dst, nominalWidthKey, nominal)
            setNestedProp(dst, inactiveWidthKey, inactive)
        },
        width: width,
        filter: (input: T, value: string) => {
            return formatNominalWidth(input, nominalWidthKey, inactiveWidthKey).toLowerCase().indexOf(value) !== -1
        },
        sort: (a: T, b: T) => {
            const aVal = formatNominalWidth(a, nominalWidthKey, inactiveWidthKey);
            const bVal = formatNominalWidth(b, nominalWidthKey, inactiveWidthKey);
            if(aVal === bVal) return 0;
            if(aVal < bVal) return -1;
            return 1;
        },
    } as any;
}

export function parseNominalWidth(value: string, parser: typeof metricParser) {

    const parts = value.split(",").filter(v => v.trim() !== "");

    let nominal: string;
    let inactive: string;

    if(parts.length === 1) {
        nominal = parser("", parts[0].trim());
        inactive = "";
    } else if(parts.length >= 2) {
        nominal = parser("", parts[0].trim());
        inactive = parser("", parts[1].trim());
    } else {
        nominal = "";
        inactive = "";
    }

    return {nominal, inactive};
}

export function metricParser(raw: string, input: string): string {
    if(input === "") return ""
    const result = MetricParser.parse(input);
    return new MetricDistance(result.mm).toString()
}
export function imperialParser(raw: string, input: string): string {
    if(input === "") return ""
    const result = ImperialParser.parse(input);
    return ImperialDistance.fromFeetAndInches(result).toString()
}

export function imperialInchParser(raw: string, input: string): string {
    const result = ImperialParser.parse(input);
    return ImperialDistance.fromFeetAndInches(result).toInchesString()
}

export function SelectCol<T extends {}>(name: string, key: NestedKeyOf<T>, options: (input: SelectInput<T>) => ({display: string, value: string} | null)[], opts?: {freeSolo: boolean}) {
    return AdjustCol(StringCol(name, key), {
        editable: {
            type: "select",
            options: ((input: SelectInput<T>) => {
                return options(input).filter(v => !!v) as any
            }) as any,
            freeSolo: opts?.freeSolo,
        }
    })
}

export function Select2Col<T extends TID>(name: string, key: NestedKeyOf<T>, options: string[]) {
    const opts = options.map(o => ({
        display: o,
        value: o,
    }))

    return AdjustCol<T>(StringCol<T>(name, key), {
        editable: {
            type: "select",
            options: () => opts,
        }
    })
}

export function BoolCol<T extends object>(name: string, key: NestedKeyOf<T>, width=100) {
    return {
        name: name,
        render: (o: T) => lookupNestedProp(o, key) ? "Yes" : "No",
        width: width,
        filter: keyMatch(key),
        sort: sortString(key),
    } as any;
}

export function CheckboxCol<T extends object>(name: string, key: NestedKeyOf<T>, width=100) {
    return {
        name: name,
        render: (o: T) => <CheckboxItem<T> value={o} lookup={key as any} />,
        width: width,
        filter: keyMatch(key),
        sort: sortString(key),
        editable: {
            type: "checkbox",
        },
        editKey: key,
    } as any;
}

function CheckboxItem<T extends object>(props: {
    value: T
    lookup: NestedKeyOf<T>
}) {
    const edit = useContext(EditContext);
    const update = (val: boolean) => {
        const obj = Object.assign({}, props.value);
        // @ts-ignore
        obj.updatedAt = "2000-01-01T01:11:22.264147Z" // special format for backend, backend will overwrite this value
        setNestedProp(obj, props.lookup, val)
        edit.update(obj)
    }

    const value = lookupNestedProp(props.value, props.lookup);

    return (
        <div onClick={() => {
            update(!value);
        }}>
            <input type="checkbox" checked={value}
                onClick={e => e.stopPropagation()}
                onChange={e => update(e.currentTarget.checked)} />
        </div>
    );
}

export function PrefixSearch<T extends TID>(input: Column<T>) {
    return Object.assign(input, {
        // @ts-ignore
        filter: keyPrefixMatch(input.editKey),
    })
}

export function DateCol<T extends object>(name: string, key: NestedKeyOf<T>, format: string, width=100) {
    return {
        name: name,
        render: (o: T) => {
            const v = lookupNestedProp(o, key);
            if(!v) return "";
            return moment.utc(v).format(format)
        },
        editable: {type: "date", format: format},
        editKey: key,
        width: width,
        filter: keyMatch(key),
        sort: sortDate(key),
    } as any;
}

export function Nullable<T extends TID>(opt: Column<T>) {
    return Object.assign(opt, {
        nullable: true,
    })
}

export function NumberCol<T extends object>(name: string, key: NestedKeyOf<T>, width=100) {
    return {
        name: name,
        render: (o: T) => (lookupNestedProp(o, key) as any)?.toString(),
        editable: {type: "number"},
        editKey: key,
        width: width,
        alignRight: true,
        filter: numberMatch(key),
        sort: sortNumeric(key),
    } as any;
}

export function WithHeaderHint<T extends TID>(col: Column<T>, hint: string) {
    return Object.assign(col, {
        headerHint: hint,
    })
}

export function centsParseValue(raw: string, value: string) {
    value = value.replace("$", "")

    try {
        value = Calculator.eval(value).toString()
        return parseCents(value)
    } catch (e) {
        return parseCents(value);
    }
}

export function renderCents(dt: any, key: string) {
    return formatCents(lookupNestedProp(dt, key))
}

export function CentsCol<T extends object>(name: string, key: NestedKeyOf<T>, width=100) {
    return {
        name: name,
        // @ts-ignore
        render: (dt: T) => renderCents(dt, key),
        editable: {
            type: "number",
            parseValue: centsParseValue,
        },
        editKey: key,
        sort: sortNumeric(key),
        width: width,
        alignRight: true,
    } as any
}

export function sortDate<T extends object>(key: NestedKeyOf<T>) {
    return (a: T, b: T) => {
        const aRaw = lookupNestedProp(a, key);
        const bRaw = lookupNestedProp(b, key);

        if(!aRaw && !bRaw) {
            return 0
        } else if(aRaw && !bRaw) {
            return -1
        } else if(!aRaw && bRaw) {
            return 1
        }

        const aVal = moment(aRaw).valueOf();
        const bVal = moment(bRaw).valueOf();
        if(aVal === bVal) return 0;
        if(aVal < bVal) return -1;
        return 1;
    }
}

export function sortNumeric<T extends object>(key: NestedKeyOf<T>) {
    return (a: T, b: T) => {
        const aVal = lookupNestedProp(a, key)
        const bVal = lookupNestedProp(b, key);
        if(aVal === bVal) return 0;
        if(aVal < bVal) return -1;
        return 1;
    }
}

export function sortString<T extends object>(key: NestedKeyOf<T>) {
    return (a: T, b: T) => {
        const aVal = lookupNestedProp(a, key)
        const bVal = lookupNestedProp(b, key);
        return sortStringInner(aVal, bVal)
    }
}

export function sortStringInner(aVal: string | null | undefined, bVal: string | null | undefined) {
    if(aVal === bVal) return 0;
    if(aVal === null || aVal === undefined) aVal = "";
    if(bVal === null || bVal === undefined) bVal = "";
    if(aVal < bVal) return -1;
    return 1;
}

export type Option = {
    display: string;
    value: any;
    disabled?: boolean
    subHeading?: boolean;
}

export function selectRenderer<T extends TID>(data: T, col: Column<T>) {
    const editable = getEditable(col, data);
    if(editable?.type !== "select") return "Error: invalid select column";
    const editKey = col.editKey;
    if(!editKey) return "Error: invalid select column";

    const value = lookupNestedProp(data, editKey);
    return first(editable.options(dummySelectWrapper(data)), o => o.value === value)?.display || "";
};

export function dummySelectWrapper<T extends object>(input: T): SelectInput<T> {
    return {
        props: (...keys: (NestedKeyOf<T> | keyof T)[]) => {
            const obj = {} as any;
            keys.map(k => {
                // @ts-ignore
                const value = lookupNestedProp(input, k)
                // @ts-ignore
                setNestedProp(obj, k, value);
            })

            return obj as T;
        }
    }
}

export type SelectInput<T extends object> = {

    // props gives the caller access to those properties in the row object
    // this is used to determine dependence and clear values if the parent is changed
    props(...k: (NestedKeyOf<T> | keyof T)[]): FilteredObject<T>
}

export type FilteredObject<T> = T;

export type TEditable<T extends TID> =
    ({
        type: "string"
        autoFormat?: "phone" | "email" | "postal";
    } |
        { type: "long-string"} |
        { type: "checkbox"} |
        {type: "number"} | {
        type: "date",
        format?: string;
    } | {
        type: "select",
        options: (data: SelectInput<T>) => Option[],
        freeSolo?: boolean // default false
    } | {
        type: "lookup",
        displayKey: NestedKeyOf<T>,
        getSelected?: (input: T) => any[];
        options: (search: string, data: SelectInput<T>) => Promise<Option[]>,
        multiple?: (input: Option[]) => Option;
    } | {
        type: "custom",
        paste: (row: T, value: string) => void,
        copy: (row: T) => string,
        render: (props: {
            anchor: any;
            row: T;
            width: number;
            initialValue: string;
            onCancel(): void;
            onDone(value: T): Promise<any>;
        }) => JSX.Element
    } | {
        type: "button",
        render: (props: {
            anchor: any;
            row: T;
            width: number;
            onCancel(): void;
            onDone(value: T): Promise<any>;
        }) => JSX.Element
    }) & {
    onChangeBeforeSave?: (row: T, value: string) => void;
    parseValue?: (raw: any, str: string) => any;
    validate?: (str: string) => void; // throws if there is an error
}

export type Column<T extends TID> = {
    name: string;
    nullable?: boolean;
    renderName?: () => any;
    render: (data: T, col: Column<T>) => any;
    alignRight?: boolean;
    isChangedSinceBackup?: (data: T) => boolean;
    editable?: TEditable<T>

    editableFunc?: (data: T) => {
        editable: TEditable<T>
        editKey?: NestedKeyOf<T>;
    } | null;

    disabled?: (input: T) => boolean
    showHintOnFocus?: (props: { anchorEl: any; row: T; }) => any;
    editObj?: (dst: T, src: T, cellValue: string) => void;
    editKey?: NestedKeyOf<T>;
    width: number;
    maxWidth?: number;
    renderFilter?: (props: RenderFilterProps) => any;
    filter?: (row: T, input: string) => boolean;
    sort?: (a: T, b: T) => -1 | 0 | 1;
    fixed?: boolean;

    headerMenuItems?: () => {
        name: string;
        onShow?(onDone: () => void): any;
        onClick?(): void;
    }[]
    headerHint?: string;
}

type RenderFilterProps = {
    onChange(value: string): void;
    value: string;
}

export function keyMatch<T extends object>(key: NestedKeyOf<T>) {
    return (el: T, input: string) => {
        return (lookupNestedProp(el, key)?.toString() || "").toLowerCase().indexOf(input.toLowerCase()) !== -1;
    };
}

export function prefixMatch(value: string | undefined | null, search: string) {
    if(!value) return false;
    return value.toLowerCase().indexOf(search.toLowerCase()) === 0;
}

export function keyPrefixMatch<T extends object>(key: NestedKeyOf<T>) {
    return (el: T, input: string) => {
        return prefixMatch(lookupNestedProp(el, key) || "", input)
    };
}

export function numberMatch<T extends object>(key: NestedKeyOf<T>) {
    return (el: T, input: string) => {
        return (lookupNestedProp(el, key) as number || 0).toString() === input.trim();
    };
}

export type NestedKeyOf<ObjectType extends object> =
    {[Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
        ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
        : `${Key}`
    }[keyof ObjectType & (string | number)];

export function lookupNestedProp<T extends object>(el: T, prop: NestedKeyOf<T>): any {
    if(!prop) return null;

    let parts = (prop as string).split(".");

    return parts.reduce((obj, prop) => {
        if(typeof obj !== "object") return null;
        if(obj === null) return null;

        if(obj.hasOwnProperty(prop)) {
            // @ts-ignore
            return obj[prop]
        }

        return null;
    }, el)
}

export function setNestedProp<T extends object>(el: T, prop: NestedKeyOf<T>, value: any) {
    if(typeof el !== "object") return;
    if(el === null) return;

    let parts = (prop as string).split(".");
    let index = 0;

    parts.reduce((obj, prop) => {
        const isEnd = (index+1 === parts.length);
        index++;

        if(isEnd) {
            // @ts-ignore
            obj[prop] = value;
            return undefined;
        }

        if(!obj.hasOwnProperty(prop)) {
            // @ts-ignore
            obj[prop] = {};
        }

        // @ts-ignore
        let next = obj[prop];
        if(typeof next !== "object" || next === null) {
            console.log(el);
            throw new Error("unaddressable '" + prop + "' in setNestedProp()")
        }

        return next;
    }, el);
}

export type FetchContext = {
    cancelled: boolean;
}

type GroupKey = string

export type Group<T> = {
    rows: T[];
    key: string;
}

export type GroupProps<T> = {
    rows: T[];
    name: string;

    all: Group<T>[];
}

export type GroupByProp<T> = {
    groupFx: (input: T) => string,
    fixedGroups?: string[]
    groupFilter?: (input: string) => boolean,
    groupSorter: (a: string, b: string, aGroup: Group<T>, bGroup: Group<T>) => number,
    beforeGroup?: (props: GroupProps<T>) => any
    afterGroup?: (props: GroupProps<T>) => any

    hasBeforeGroup?: (props: Group<T>) => boolean
    hasAfterGroup?: (props: Group<T>) => boolean
}

export type CellCustomize<T> = {
    backgroundColor?: (input: T, column: Column<any>) => any;
    onFocus?: (input: T, column: Column<any>, anchor: any) => any;
}

function migrateTableConfig(input: TableProjectConfigResult, rawColumns: (Column<any>|null|false)[], onMigrate: (value: TableProjectConfig) => void): TableProjectConfig {
    if("version" in input) {
        return input
    }

    const cfg: TableProjectConfig = {
        version: 2,
        byName: {},
        maxFixedIndex: input.maxFixedIndex,
        sortBy: input.sortBy,
    }

    rawColumns.map(c => {
        if(!c) return;

        cfg.byName[c.name] = {
            width: 100,
            hidden: false,
            index: -1,
        }
    })

    input.hidden?.map(h => {
        try {
            const col = rawColumns[h]
            if(!col) return;

            cfg.byName[col.name].hidden = true;
        } catch (e) {
            // do nothing
        }
    })

    input.widths?.map((w, index) => {
        try {
            const col = rawColumns[index]
            if(!col) return;

            cfg.byName[col.name].width = w;
        } catch (e) {
            // do nothing
        }
    })

    if(input.columnOrder) {
        input.columnOrder.map((position, index) => {
            try {
                const c = rawColumns[index]
                if(!c) return;

                cfg.byName[c.name].index = index;
            } catch (e) {
                // do nothing
            }
        })
    }

    onMigrate(cfg);
    return cfg;
}

export function Table<T extends TID>(props: {
    name: string;
    overridePrefsName?: string
    globalPrefsName?: string;
    onRowClick?(input: T): void;
    fetch: (ctx: FetchContext) => Promise<T[]>;
    fetchDeps: any[];
    columnDeps?: any[];
    locked?: boolean
    columns: (Column<T>|null|false)[];
    onChangeHook?: (src: T | null, dst: T) => void;
    onChange?: (input: T) => Promise<any>;
    sort?: (a: T, b: T) => number;

    cellCustomize?: CellCustomize<T>;

    // return a modified object that we can pass to onChange()
    onDrag?: (input: DragResult<T>) => Promise<{sortByCol: string|null}>

    insert?: Insert;
    noHorizontalScroller?: boolean;
    noFooter?: boolean;
    groupBy?: GroupByProp<T>
}) {

    const columnDeps = props.columnDeps || [];

    // columns should never change and are defined as a literal, so useMemo to prevent from
    // column reference changes from affecting child components useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => props.columns.filter(c => !!c) as Column<T>[], columnDeps);

    const [data, setData] = useState<T[]>();
    const [personalize, setPersonalize] = useState<TableProjectConfig|null>();
    const [error, setError] = useState<string>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetch = useMemo(() => props.fetch, props.fetchDeps);
    const columnsRef = useRef(columns);
    columnsRef.current = columns;

    const getPersonalization = useCallback(async () => {
        const name = props.overridePrefsName || props.name;
        let cfg = await api.tableConfig.get({name: name});

        // fallback to global pref
        if(!cfg && props.globalPrefsName) {
            cfg = await api.tableConfig.get({name: props.globalPrefsName});
        }

        if(!cfg) {
            return {
                version: 2,
                byName: {},
                maxFixedIndex: -1,
            };
        }

        return migrateTableConfig(cfg, columnsRef.current, (migrated) => {
            api.tableConfig.set({
                name: name,
                value: migrated
            })
        })
    }, [props.name, props.globalPrefsName, props.overridePrefsName])

    const load = useCallback((merge: boolean = false) => {
        const ctx = {
            cancelled: false,
        }

        const onCancel = () => {
            ctx.cancelled = true;
        }

        const promise = (async function() {
            try {
                setError(undefined);

                if(merge) {

                    const data = await fetch(ctx);
                    if (ctx.cancelled) return;

                    setData(old => data.map((o) => {
                        if(!old) return o;

                        const oldItem = first(old, oldItem => oldItem.id === o.id);
                        if(oldItem) {
                            // @ts-ignore
                            if(o.hasOwnProperty("updatedAt") && o.updatedAt === oldItem.updatedAt) {
                                return oldItem;
                            }
                        }

                        return o;
                    }));
                } else {
                    setData(undefined);

                    const [data, personalize] = (await Promise.all([fetch(ctx), getPersonalization()])) as [T[], TableProjectConfig];
                    if (ctx.cancelled) return;

                    setPersonalize(personalize)
                    setData(data);
                }
            } catch(e: any) {
                if(ctx.cancelled) return;

                console.error(e);
                setData(undefined);
                setError(e.toString());
            }
        })();

        return {promise, onCancel};
    }, [fetch, getPersonalization]);

    const loadSimple = useCallback((merge: boolean =false) => {
        return load(merge).promise;
    }, [load]);

    useEffect(() => {
        const ld = load();
        return () => ld.onCancel();
    }, [load])

    const updatePersonalize = useCallback(async (value: TableProjectConfig, updateGlobal: boolean = false) => {
        setPersonalize(value);

        let name = props.overridePrefsName || props.name;
        if(updateGlobal && props.globalPrefsName) {
            name = props.globalPrefsName;
        }

        await api.tableConfig.set({
            name: name,
            value: value,
        })
    }, [props.name, props.overridePrefsName, props.globalPrefsName])

    const snack = useSnackbar();
    const onDragRef = useSyncedRef(props.onDrag);
    const onChangeRef = useSyncedRef(props.onChange);
    const personalizeRef = useSyncedRef(personalize)
    const onDrag = useMemo(() => {
        if(!onDragRef.current) return undefined;
        return async (input: DragResult<T>) => {
            if(!onDragRef.current) return;
            if(!onChangeRef.current) return;

            try {
                snack.loading("Updating")
                const result = await onDragRef.current(input);

                await updatePersonalize(Object.assign({}, personalizeRef.current, {
                    sortBy: result.sortByCol ? {
                        column: result.sortByCol,
                        reverse: false,
                    } : null,
                }));

                await loadSimple(false);
                snack.success("Updated")
            } catch (e: any) {
                snack.error(e.toString())
            }
        }
    }, [snack, loadSimple, onChangeRef, onDragRef, personalizeRef, updatePersonalize])

    const tblCtx = useMemo(() => ({
        reload: loadSimple,
        columns: columns,
    }), [loadSimple, columns])

    const {onData} = useContext(TableSelectionContext);
    useEffect(() => {
        onData(props.name, data || blankArray)
    }, [props.name, data, onData]);

    const onRowClickRef = useSyncedRef(props.onRowClick);
    const hasRowClick = !!props.onRowClick;

    const onRowClick = useMemo(() => {
        if(hasRowClick) {
            return (input: T) => {
                if(onRowClickRef.current) {
                    onRowClickRef.current(input);
                }
            }
        }

        return undefined;
    }, [hasRowClick, onRowClickRef]);

    const groupFx = props.groupBy?.groupFx || defaultGroupFx;
    const groupSorter = props.groupBy?.groupSorter || defaultGroupSorter;
    const groupNames = props.groupBy?.fixedGroups || blankArray as string[];
    const groupFilter = props.groupBy?.groupFilter || includeAllFilter;

    const groupedData = useMemo(() => {
        let obj = {} as {[k:string]: Group<T>}

        groupNames.map(n => {
            if(!obj.hasOwnProperty(n)) {
                obj[n] = {
                    key: n,
                    rows: [],
                }
            }

            return null;
        })

        obj = (data || blankArray as T[]).reduce((acc, row) => {
            const key = groupFx(row);
            if(!acc.hasOwnProperty(key)) {
                acc[key] = {
                    key: key,
                    rows: [],
                };
            }

            acc[key].rows.push(row);
            return acc;
        }, obj)

        let list = Object.values(obj) as Group<T>[];
        list.sort((a, b) => {
            return groupSorter(a.key, b.key, a, b);
        });

        list.map(l => console.log("srt", l.key))

        return list;
    }, [data, groupFx, groupNames, groupSorter]);

    const hasGroupStart = useMemo(() => {
        if(!props.groupBy) return undefined;
        if(!props.groupBy.beforeGroup) return undefined;

        let t2Filter = props.groupBy.hasBeforeGroup || trueFx
        let groupFilter = props.groupBy.groupFilter || trueFx

        return (input: Group<T>) => {
            if(!groupFilter(input.key)) return false;
            return t2Filter(input);
        }
    }, [props.groupBy])

    const hasGroupEnd = useMemo(() => {
        if(!props.groupBy) return undefined;
        if(!props.groupBy.afterGroup) return undefined;

        let t2Filter = props.groupBy.hasAfterGroup || trueFx
        let groupFilter = props.groupBy.groupFilter || trueFx

        return (input: Group<T>) => {
            if(!groupFilter(input.key)) return false;
            return t2Filter(input);
        }
    }, [props.groupBy])

    if(error) {
        return (
            <Alert color="error">
                <div>{error}</div>
                <div><Button onClick={() => load()}>Retry</Button></div>
            </Alert>
        );
    }

    return (
        <TableContext.Provider value={tblCtx}>
            <TablePersonalizeProvider
                name={props.name} value={personalize} onChange={updatePersonalize}
                columns={columns}
                sort={props.sort}
                hasGlobal={!!props.globalPrefsName}
            >
                <FilterProvider groupFilter={groupFilter} data={groupedData} tableName={props.name} columns={columns}>
                    <TableOuter
                        name={props.name}
                        cellCustomize={props.cellCustomize}
                        columns={columns}
                        onChange={props.onChange}
                        onChangeHook={props.onChangeHook}
                        data={data}
                        locked={props.locked}
                        groupBy={props.groupBy}
                        hasGroupStart={hasGroupStart}
                        hasGroupEnd={hasGroupEnd}
                        onDrag={onDrag}
                        noFooter={props.noFooter}
                        noHorizontalScroller={props.noHorizontalScroller}
                        onRowClick={onRowClick}
                        insert={props.insert}
                    />
                </FilterProvider>
            </TablePersonalizeProvider>
        </TableContext.Provider>
    );
}

function TableOuter<T extends TID>(props: {
    name: string;
    cellCustomize?: CellCustomize<T>;
    columns: Column<T>[]
    data?: T[];
    locked?: boolean;
    groupBy?: GroupByProp<T>
    hasGroupStart?: (input: Group<T>) => boolean;
    hasGroupEnd?: (input: Group<T>) => boolean;
    noFooter?: boolean;
    noHorizontalScroller?: boolean;
    insert?: Insert;

    onChange?: (input: T) => Promise<any>;
    onChangeHook?: (src: T | null, dst: T) => void;
    onDrag?(input: DragResult<T>): void;
    onRowClick?(input: T): void;
}) {
    const filtered = useContext(FilterContextPrivate);

    // keeping data relatively the same during refreshes allows us to keep the scroll position in the TableInner
    const whileLoadingDataRef = useRef(filtered)
    if(props.data !== undefined) {
        whileLoadingDataRef.current = filtered;
    }

    return (
        <EditProvider name={props.name} cellCustomize={props.cellCustomize} columns={props.columns}
                      onChangeHook={props.onChangeHook}
                      onChange={props.onChange} data={filtered} locked={props.locked}>
            { props.data === undefined ?
                <div style={{padding: 32, display: "flex", alignItems: "center"}}>
                    <div style={{maxWidth: 300, width: "100%"}}>
                        <LinearProgress variant="indeterminate"/>
                    </div>
                </div> : null}
            <div style={{
                display: props.data === undefined ? "none" : "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "hidden",
            }}>
                <TableInner
                    columns={props.columns}
                    data={props.data === undefined ? whileLoadingDataRef.current : filtered}
                    onRowSelect={props.onRowClick}
                    insert={props.insert}
                    noFooter={props.noFooter}
                    noHorizontalScroller={props.noHorizontalScroller}
                    beforeGroup={props.groupBy?.beforeGroup}
                    afterGroup={props.groupBy?.afterGroup}
                    hasGroupStart={props.hasGroupStart}
                    hasGroupEnd={props.hasGroupEnd}
                    onDrag={props.onDrag}
                />
            </div>
        </EditProvider>
    )
}

const trueFx = () => true

const includeAllFilter = () => true;
const defaultGroupFx = () => "default";
const defaultGroupSorter = (a: GroupKey, b: GroupKey) => {
    if(a < b) return -1;
    if(b > a) return 1;
    return 0;
};
const blankArray: any = [];

export const TableSelectionContext = createContext({
    subscribe: (tableName: string, callback: (input: object|null) => void) => {
        return () => {}
    },
    update: (tableName: string, value: object|null) => {

    },
    refresh: (tableName: string)  => {},
    refreshAll: () => {},
    onRefresh: (tableName: string, callback: () => Promise<any>)  => {
        return () => {};
    },
    onData: (tableName: string, value: any[]) => {

    },
    data: (tableName: string, cb: (value: any) => void) => {
        return () => {}
    },
    getOnce: (table: string) => {
        return null as any;
    },
})

export function useTableData<T>(name: string) {
    const {data} = useContext(TableSelectionContext);
    const [dataCopy, setDataCopy] = useState<T[]>([]);

    useEffect(() => {
        return data(name, setDataCopy);
    }, [name, data]);

    return dataCopy;
}

export function useTableSelection<T>(name: string) {
    const {subscribe} = useContext(TableSelectionContext);
    const [value, setValue] = useState<T|null>(null)

    useEffect(() => {
        return subscribe(name, setValue as any)
    }, [name, subscribe]);

    return value;
}

export function useTableRefresher(name: string) {
    const {refresh} = useContext(TableSelectionContext);

    return useCallback(() => {
        return refresh(name);
    }, [refresh, name]);
}

export function TableSelectionContextProvider(props: PropsWithChildren<{}>) {
    const emit = useMemo(() => new EventEmitter<{table: string, value: object|null}>(), []);
    const dataEmitter = useMemo(() => new EventEmitter<{table: string, data: any}>(), []);
    const last = useRef({} as any)
    const lastData = useRef({} as any)
    const refresher = useRef({} as any)

    const ctx = useMemo(() => {
        return {
            subscribe: (tableName: string, callback: (input: object|null) => void) => {
                callback(last.current[tableName] || null);

                const sub = emit.subscribe(input => {
                    if(input.table === tableName) {
                        callback(input.value);
                    }
                })

                return () => sub.cancel();
            },
            update: (tableName: string, value: object|null) => {
                emit.emit({
                    table: tableName,
                    value: value,
                });
                last.current[tableName] = value;
            },
            refresh: (table: string) => {
                const fx = refresher.current[table];
                if(!fx) return Promise.reject("a table with name '" + table + "' isn't available to be refreshed");
                return fx();
            },
            refreshAll: async () => {
                const promises = [];

                for(let a in refresher.current) {
                    const fx = refresher.current[a];
                    if(!fx) continue;
                    promises.push(fx());
                }

                return Promise.all(promises);
            },
            onRefresh: (table: string, callback: () => Promise<any>) => {
                refresher.current[table] = callback;

                return () => {
                    refresher.current[table] = null;
                }
            },
            onData: (table: string, data: any) => {
                dataEmitter.emit({
                    table: table,
                    data: data,
                });

                lastData.current[table] = data;
            },
            data: (table: string, callback: (input: any) => void) => {
                if(lastData.current[table]) {
                    callback(lastData.current[table]);
                }

                const sub = dataEmitter.subscribe(d => {
                    if(d.table === table) {
                        callback(d.data);
                    }
                })

                return () => sub.cancel();
            },
            getOnce: (table: string) => {
                if(lastData.current[table]) {
                    return lastData.current[table];
                }

                return null;
            }
        }
    }, [emit, dataEmitter]);

    return <TableSelectionContext.Provider value={ctx}>
        {props.children}
    </TableSelectionContext.Provider>
}

export const TableContext = createContext({
    reload: (merge: boolean = false) => (Promise.resolve(true) as Promise<any>),
    columns: [] as Column<any>[]
})

const separationBorder = "3px solid " + grey["300"];
const borderWidth = 3;

const TableInner: any = React.memo(function TableInner<T extends TID>(props: {
    data: Group<T>[];
    columns: Column<T>[]
    insert?: Insert;
    noFooter?: boolean;
    onRowSelect?(input: T): void
    onDrag?(input: DragResult<T>): void;
    noHorizontalScroller?: boolean;
    beforeGroup?: (props: GroupProps<T>) => any
    afterGroup?: (props: GroupProps<T>) => any
    hasGroupStart?: (group: Group<T>) => boolean;
    hasGroupEnd?: (group: Group<T>) => boolean;
    blankElement?: any;
}) {

    const hasFilter = props.columns.filter(v => !!v.filter).length > 0;
    const ctx = useContext(TablePersonalizeContext);
    const cfgLookupByName = ctx.lookupByName;
    const getTableColumns = ctx.getTableColumns;

    const personalizeColumns = useMemo(() => {
        return getTableColumns(props.columns)
    }, [getTableColumns, props.columns]);
    const {standardColumns, fixedColumns, lastVisibleName, standardWidth, fixedWidth} = personalizeColumns

    const fixedOffset = ctx.maxFixedIndex + 1;

    const list = useMemo(() => {
        return selectMany(props.data, d => d.rows);
    }, [props.data]);
    const dataRef = useSyncedRef(list);

    const {visibleIds} = useContext(CheckboxContext);
    useEffect(() => {
        visibleIds.current = list.map(d => d.id);
    }, [visibleIds, list]);

    const render = useCallback((v: T, index: number) => {
        return <Row value={v} index={index}
                     columns={standardColumns}
                     columnConfigLookup={cfgLookupByName}
                     indexOffset={fixedOffset}
                     onRowSelect={props.onRowSelect}
                     lastVisibleColumnName={lastVisibleName}
                     isLast={index === props.data.length - 1}
                     dataRef={dataRef} />
    }, [standardColumns, props.onRowSelect, props.data.length, fixedOffset, lastVisibleName, dataRef, cfgLookupByName]);

    const renderFixed = useCallback((v: T, index: number) => (
        <Row value={v} index={index} columns={fixedColumns}
             columnConfigLookup={cfgLookupByName}
             indexOffset={0}
             onRowSelect={props.onRowSelect}
             lastVisibleColumnName={null}
             isLast={index === props.data.length-1}
             isFixed={true}
             dataRef={dataRef}
        />), [fixedColumns, props.onRowSelect, props.data.length, dataRef, cfgLookupByName]);

    const renderGroupStartFixed = useMemo(() => {
        if(!props.beforeGroup) return undefined;
        const BeforeGroup = props.beforeGroup as any;

        return (input: Group<T>) => {
            return (
                <FreeCellRow fixed={true} columns={props.columns}>
                    <BeforeGroup rows={input.rows} name={input.key} all={props.data} />
                </FreeCellRow>
            );
        }
    }, [props.beforeGroup, props.data, props.columns])

    const renderGroupStart = useMemo(() => {
        if(!props.beforeGroup) return undefined;
        const BeforeGroup = props.beforeGroup as any;

        return (input: Group<T>) => {
            return (
                <FreeCellRow fixed={false} columns={props.columns}>
                    <BeforeGroup rows={input.rows} name={input.key} all={props.data} />
                </FreeCellRow>
            );
        }
    }, [props.beforeGroup, props.data, props.columns]);


    const renderGroupEndFixed = useMemo(() => {
        if(!props.afterGroup) return undefined;
        const AfterGroup = props.afterGroup as any;

        return (input: Group<T>) => {
            return (
                <FreeCellRow fixed={true} columns={props.columns}>
                    <AfterGroup rows={input.rows} name={input.key} all={props.data} />
                </FreeCellRow>
            );
        }
    }, [props.afterGroup, props.data, props.columns])

    const renderGroupEnd = useMemo(() => {
        if(!props.afterGroup) return undefined;
        const AfterGroup = props.afterGroup as any;

        return (input: Group<T>) => {
            return (
                <FreeCellRow fixed={false} columns={props.columns}>
                    <AfterGroup rows={input.rows} name={input.key} all={props.data} />
                </FreeCellRow>
            );
        }
    }, [props.afterGroup, props.data, props.columns])

    const {scrollerRef} = useAutoScroll()
    const hiddenDetails = useMemo(() => getTableColumns(props.columns, {includeHidden: true}), [props.columns, getTableColumns]);

    return (
        <OuterWrapper>
            <VSeparator style={{
                left: fixedWidth,
                bottom: props.noFooter ? 0 : undefined,
            }} />

            <InfiniteScrollWithGroups<T>
                ref={scrollerRef as any}
                blankElement={props.blankElement}
                fixedHeader={
                    <div style={{
                        borderRight: separationBorder,
                        borderBottom: separationBorder,
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <FlexHeaderRow style={{flex: 1}} key="columns">
                            {fixedColumns
                                .map((c, index) => {
                                    const cfg = cfgLookupByName(c.name, c);
                                    const hiddenIndex = hiddenDetails.columns.findIndex(cfg => cfg.name === c.name)
                                    return <HeaderCell key={index.toString()} column={c} width={cfg.width} hidden={cfg.hidden} hiddenIndex={hiddenIndex} />
                                })}
                        </FlexHeaderRow>
                        {hasFilter && <FlexHeaderRow key="filter">
                            {fixedColumns
                                .map((c, index) => {
                                    const cfg = cfgLookupByName(c.name, c);

                                    return <FilterCell key={index.toString()} column={c} width={cfg.width}
                                                hidden={cfg.hidden}/>
                                })}
                        </FlexHeaderRow>}
                    </div>
                }
                fixedWidth={fixedWidth + borderWidth}
                header={
                    <div style={{borderBottom: separationBorder, minWidth: standardWidth}}>
                        <FlexHeaderRow key="columns">
                            {standardColumns
                                .map((c, index) => {
                                    const hiddenIndex = hiddenDetails.columns.findIndex(cfg => cfg.name === c.name)
                                    const cfg = cfgLookupByName(c.name, c);
                                    return <HeaderCell key={index.toString()} column={c} autoGrow={lastVisibleName === c.name} width={cfg.width} hidden={cfg.hidden} hiddenIndex={hiddenIndex} />
                            })}
                        </FlexHeaderRow>
                        {hasFilter && <FlexHeaderRow key="filter">
                            {standardColumns
                                .map((c, index) => {
                                    const cfg = cfgLookupByName(c.name, c);

                                    return <FilterCell key={index.toString()} column={c}
                                               autoGrow={lastVisibleName === c.name}
                                               width={cfg.width}
                                               hidden={cfg.hidden} />
                                })}
                        </FlexHeaderRow>}
                    </div>
                }
                footer={props.noFooter ? null : <Footer insert={props.insert} />}
                data={props.data}
                render={render}
                renderFixed={renderFixed}
                hasFixedData={fixedColumns.length > 0}
                renderGroupStart={renderGroupStart}
                renderGroupStartFixed={renderGroupStartFixed}
                hasGroupStart={props.hasGroupStart}
                hasGroupEnd={props.hasGroupEnd}
                renderGroupEnd={renderGroupEnd}
                renderGroupEndFixed={renderGroupEndFixed}
                noHorizontalScroll={props.noHorizontalScroller}
                onDrag={props.onDrag}
            />
        </OuterWrapper>
    )
});

function useAutoScroll() {
    const scrollerRef = useRef<Scrollable>();

    const edit = useContext(EditContext);
    const sel = edit.onSelectedCursorMoved;
    useEffect(() => {
        const sub = sel.subscribe(v => {
            const scroller = scrollerRef.current;
            if(!scroller) return;

            const box = scroller.getRect();
            if(!box) return;

            const selected = v.element.getBoundingClientRect();
            const obj = {
                bottom: box.bottom - selected.bottom,
                top: selected.top - box.top,
                left: selected.left - box.left,
                right: box.right - selected.right,
            }

            let y = 0;
            let x = 0;

            if(obj.bottom < 0) {
                y = -obj.bottom + 10;
            } else if(obj.top < 0) {
                y = obj.top - 10;
            }

            if(obj.left < 0) {
                x = obj.left - 10;
            } else if(obj.right < 0) {
                x = -obj.right + 10;
            }

            if(y !== 0 || x !== 0) {
                scroller.scrollBy(x, y)
            }
        })

        return () => sub.cancel();
    }, [sel, scrollerRef]);

    return {scrollerRef}
}

type RowProps<T extends TID> = {
    columns: Column<T>[];
    value: T;
    columnConfigLookup: (name: string, column: Column<T>, index: number) => ColumnConfig;
    lastVisibleColumnName: string | null;
    index: number;
    indexOffset: number;
    onRowSelect?(value: T): void;
    isLast: boolean;
    isFixed?: boolean;
    dataRef: MutableRefObject<T[]>
}

export const Row: any = React.memo(function<T extends TID>(props: RowProps<T>) {
    return (
        <RowInner {...props} />
    )
});

export const RowRefContext = createContext({
    element: {current: null} as MutableRefObject<HTMLDivElement|null>,
    index: {current: null} as MutableRefObject<number|null>,
});

function RowInner<T extends TID>(props: RowProps<T>) {

    const rowRef = useRef<HTMLDivElement|null>(null);

    const [selected, setSelected] = useState(-1);
    const [rangeSelected, setRangeSelected] = useState<{ min: number; max: number }>();

    const ctx = useContext(EditContext);
    const selectedRef = useRef(selected);
    selectedRef.current = selected;

    const modified = useRef<number[]>([]);
    // @ts-ignore
    const updatedAt = props.value.updatedAt;

    useEffect(() => {
        modified.current = [];
    }, [props.value, updatedAt]);

    useEffect(() => {
        const sub = ctx.onSelect.subscribeAndFireLast(input => {
            if (selectedRef.current === -1) {
                if (!input) return;
                if (input.id === props.value.id) {
                    setSelected(input.index - props.indexOffset);
                }

                return;
            }

            if (!input || input.id !== props.value.id) {
                setSelected(-1);
                return;
            }

            setSelected(input.index - props.indexOffset);
        });

        return () => sub.cancel();
    }, [ctx.onSelect, props.value, props.indexOffset]);

    useEffect(() => {
        const sub = ctx.onRange.subscribeAndFireLast(input => {
            if (!input) {
                setRangeSelected(undefined);
                return;
            }

            if (input.ids.indexOf(props.value.id) === -1) {
                setRangeSelected(undefined);
                return;
            }

            setRangeSelected({
                min: Math.min(input.fromIndex, input.toIndex),
                max: Math.max(input.fromIndex, input.toIndex),
            });
        })

        return () => sub.cancel();
    }, [ctx.onRange, props.value, props.indexOffset]);

    const clickable = !!props.onRowSelect;

    const indexRef = useSyncedRef(props.index)
    const rowCtx = useMemo(() => ({
        element: rowRef,
        index: indexRef,
    }), [rowRef, indexRef])

    return (
        <RowRefContext.Provider value={rowCtx}>
            <div
                ref={rowRef}
                className={clsx({
                    [flexDataRowClass]: true,
                    [rowSelectableClass]: clickable,
                    [rowBorderBottom]: true,
                })}
                onClick={() => {
                    if (props.onRowSelect) {
                        props.onRowSelect(props.value);
                    }
                }}
                style={{
                    borderRight: props.isFixed ? "none" : undefined,
                }}
            >
                {props.columns
                    .map((c, index) => {
                        const cfg = props.columnConfigLookup(c.name, c, index);

                        if (cfg.hidden) {
                            return null;
                        }

                        const autoGrow = c.name === props.lastVisibleColumnName;
                        const isLast = index === props.columns.length - 1;
                        const cellLocked = !!c.disabled && c.disabled(props.value);
                        const editable = getEditable(c, props.value);

                        if (!!editable && !ctx.locked && !cellLocked) {
                            if (selected === index) {
                                return (<EditCell
                                    key={index.toString()}
                                    value={(props.value as any) || ""}
                                    column={c}
                                    width={cfg.width}
                                    autoGrow={autoGrow}
                                    onRender={div => {
                                        ctx.onSelectedCursorMoved.emit({element: div})
                                    }}
                                    onModify={() => {
                                        if (modified.current.indexOf(index) === -1) {
                                            modified.current.push(index)
                                        }
                                    }}
                                />)
                            }

                            const adjustedIndex = index + props.indexOffset
                            const isRangeSelected = !!rangeSelected && rangeSelected.min <= adjustedIndex && rangeSelected.max >= adjustedIndex;
                            const isRightOfRange = isRangeSelected && rangeSelected && adjustedIndex === rangeSelected.max;
                            const isTopOfRange = isRangeSelected && ctx.onRange.lastValue?.ids.indexOf(props.value.id) === 0;

                            return (
                                <div
                                    key={index.toString()}
                                    onClick={e => {
                                        e.stopPropagation()

                                        // sometimes the text get's selected
                                        document.getSelection()?.removeAllRanges()

                                        if (e.shiftKey && (ctx.onSelect.lastValue || ctx.onRange.lastValue)) {
                                            let lastId = 0;
                                            let lastIndex = 0;

                                            const range = ctx.onRange.lastValue;
                                            const select = ctx.onSelect.lastValue;
                                            if (range) {
                                                lastId = range.fromId;
                                                lastIndex = range.fromIndex;
                                            } else if (select) {
                                                lastId = select.id;
                                                lastIndex = select.index;
                                                ctx.onSelect.emit(null);
                                            }

                                            let ids: number[] = [];
                                            let record = false;

                                            if (lastId === props.value.id) {
                                                ids.push(lastId);
                                            } else {
                                                props.dataRef.current.map((row: T) => {
                                                    if (row.id === lastId || row.id === props.value.id) {
                                                        if (!record) {
                                                            record = true;
                                                        } else {
                                                            ids.push(row.id);
                                                            record = false
                                                        }
                                                    }

                                                    if (record) {
                                                        ids.push(row.id);
                                                    }

                                                    return null;
                                                })
                                            }

                                            ctx.onRange.emit({
                                                fromId: lastId,
                                                fromIndex: lastIndex,
                                                toId: props.value.id,
                                                toIndex: index + props.indexOffset,
                                                ids: ids,
                                            })

                                            return;
                                        }

                                        ctx.onRange.emit(null);
                                        ctx.onSelect.emit({
                                            id: props.value.id,
                                            index: index + props.indexOffset,
                                            value: props.value,
                                            editing: false,
                                        });
                                    }}
                                    className={editCell + " " + (isRangeSelected ? rangeSelectedCell : standardCell)}
                                    style={{
                                        width: autoGrow ? undefined : cfg.width,
                                        flex: autoGrow ? 1 : undefined,
                                        minWidth: cfg.width,
                                        textAlign: c.alignRight ? "right" : undefined,
                                        borderRight: isLast ? "none" : (isRangeSelected && !isRightOfRange) ? "1px solid transparent" : undefined,
                                        borderTop: (isRangeSelected && !isTopOfRange) ? "1px solid transparent" : undefined,
                                        backgroundColor: ctx.cellCustomize?.backgroundColor?.(props.value, c) || undefined,
                                        whiteSpace: "pre-wrap",
                                    }}>

                                    {modified.current.indexOf(index) !== -1 ? null : c.render(props.value, c) || <>&nbsp;</>}
                                </div>
                            )
                        }

                        return (
                            <div
                                key={index.toString()}
                                className={cell}
                                onClick={() => {
                                    // show selected for row-select and to blur other cells if this one is clicked
                                    ctx.onSelect.emit({
                                        id: props.value.id,
                                        index: index + props.indexOffset,
                                        value: props.value,
                                        editing: false,
                                    });
                                }}
                                style={{
                                    width: autoGrow ? undefined : cfg.width,
                                    flex: autoGrow ? 1 : undefined,
                                    minWidth: cfg.width,
                                    textAlign: c.alignRight ? "right" : undefined,
                                    borderRight: isLast ? "none" : undefined,
                                    backgroundColor:
                                        ctx.cellCustomize?.backgroundColor?.(props.value, c) || (cellLocked ? grey["100"] : undefined),
                                    whiteSpace: "pre-wrap",
                                }}>
                                {c.render(props.value, c)}
                            </div>
                        )
                    })}
            </div>
        </RowRefContext.Provider>
    )
}

const rowBorderBottom = "inf-table-row-border-bottom";

export const FreeCellContext = createContext({
    info: (index: number, colSpan: number) => {
        return {
            visible: true,
            width: 250,
        }
    }
});

export function FreeCellRow(props: PropsWithChildren<{
    fixed: boolean;
    columns: Column<any>[]
}>) {
    const ctx = useContext(TablePersonalizeContext);
    const getTableColumns = ctx.getTableColumns;
    const info = useMemo(() => {
        return getTableColumns(props.columns, {
            includeHidden: false,
        })
    }, [props.columns, getTableColumns]);

    const cols = props.fixed ? info.fixedColumns : info.standardColumns;

    const validWidths = cols.map((v, index) => {
        const cfg = ctx.lookupByName(v.name, v, index)
        if(cfg.hidden) return 0;
        return cfg.width;
    })

    const totalWidth = sum(validWidths) + 1;

    const lookupByName = ctx.lookupByName
    const value = useMemo(() => {
        return {
            info: (index: number, colSpan: number) => {

                const section = cols.slice(index, index + colSpan)
                const width = sum(section.map(c => {
                    const cfg = lookupByName(c.name, c)
                    if(cfg.hidden) return 0;
                    return cfg.width;
                }));

                return {
                    visible: width > 0,
                    width: width,
                }
            }
        }
    }, [cols, lookupByName]);

    if(typeof props.children === "string") {
        if(!props.children) return null;
        // @ts-ignore
    } else if(!props.children) {
        return null;
    }

    return (
        <FreeCellContext.Provider value={value}>
            <div className={flexDataRowClass} style={{minWidth: totalWidth, flex: 1, borderBottom: "1px solid " + grey["200"]}}>
                {props.children}
            </div>
        </FreeCellContext.Provider>
    )
}

export function FreeCell(props: PropsWithChildren<{
    colSpan?: number;
    index: number;
    grow?: boolean;
    noPadding?: boolean;
}>) {
    const ctx = useContext(FreeCellContext);
    const info = ctx.info(props.index, props.colSpan || 1);

    if(!info.visible) {
        return null;
    }

    return (
        <div className={cell} style={{
            paddingTop: props.noPadding ? 0 : 4,
            paddingBottom: props.noPadding ? 0 : 4,
            paddingLeft: props.noPadding ? 0 : undefined,
            paddingRight: props.noPadding ? 0 : undefined,
            width: props.grow ? undefined:  info.width,
            flex: props.grow ? 1 : undefined,
            borderRight: props.grow ? "none" : undefined,
        }}>{props.children}</div>
    )
}

const rowSelectableClass = css({
    cursor: "pointer",
})

function HeaderCell<T extends TID>(props: {
    column: Column<T>
    width: number;
    autoGrow?: boolean;
    hidden: boolean;
    hiddenIndex: number;
}) {
    const [show, setShow] = useState<HTMLDivElement|null>(null);
    const ctx = useContext(TablePersonalizeContext);
    const [showDialog, setShowDialog] = useState<{callback: (onDone:() => void) => any}>();
    const tbl = useContext(TableContext);
    const reloadRef = useSyncedRef(tbl.reload)

    const onDone = useCallback(() => {
        reloadRef.current();
        setShowDialog(undefined)
    }, [reloadRef]);

    if(showDialog) {
        return showDialog.callback(onDone);
    }

    if(props.hidden) return null

    const headerItems = props.column.headerMenuItems ? props.column.headerMenuItems() : [];
    const headerHint = props.column.headerHint;

    return (
        <>
            <HeaderCellInner
                onContextMenu={e => {
                    e.preventDefault();
                    setShow(e.currentTarget);
                }}
                onClick={e => {
                    if(props.column.sort) {

                        if(props.column.name === ctx.sortBy?.column) {
                            if(ctx.sortBy.reverse) {
                                ctx.onChange({
                                    sortBy: null,
                                });

                                return;
                            }

                            ctx.onChange({
                                sortBy: {
                                    column: props.column.name,
                                    reverse: true,
                                }
                            });

                            return;
                        }

                        ctx.onChange({
                            sortBy: {
                                column: props.column.name,
                                reverse: false,
                            }
                        });
                    }
                }}
                style={{
                    width: props.autoGrow ? undefined: props.width-4,
                    flex: props.autoGrow ? 1 : undefined,
                    minWidth: props.width-4,
                    background: "white",
                }}>
                {props.column.renderName ? props.column.renderName() : props.column.name}
                <div style={{flex: 1}} />
                {headerHint && <PopoverDesc value={headerHint} icon={<InfoIcon fontSize="inherit" color="inherit" />} />}
                <SortInfo name={props.column.name} />
            </HeaderCellInner>
            {show && <Menu open anchorEl={show} onClose={() => {
                setShow(null);
            }}>
                <MenuItem onClick={() => {
                    setShow(null);

                    const cfg = ctx.lookupByName(props.column.name)
                    const byName = Object.assign({}, ctx.columns, {
                        [props.column.name]: Object.assign({}, cfg, {
                            hidden: true,
                        })
                    });

                    ctx.onChange({
                        byName,
                    })
                }}>Hide Column</MenuItem>
                <MenuItem onClick={() => {
                    setShow(null);
                    ctx.showCustomizeColumns.current();
                }}>Customize Columns...</MenuItem>
                <MenuItem onClick={() => {
                    setShow(null);

                    ctx.onChange({
                        maxFixedIndex: props.hiddenIndex,
                    })
                }}>Lock Columns to Left</MenuItem>
                {ctx.maxFixedIndex >= 0 && <MenuItem onClick={() => {
                    setShow(null);
                    ctx.onChange({
                        maxFixedIndex: -1,
                    })
                }}>Unlock Columns</MenuItem>}
                {headerItems.length > 0 && <Divider />}
                {headerItems.map(h => <MenuItem key={h.name} onClick={() => {
                    if(h.onShow) {
                        setShowDialog({callback: h.onShow})
                    }

                    if(h.onClick) {
                        h.onClick();
                    }

                    setShow(null);
                }}>
                    {h.name}
                </MenuItem>)}
            </Menu>}
            <DraggableDivider width={props.width} column={props.column} />
        </>
    );
}

function PopoverDesc(props: {
    value: any;
    icon: any;
}) {

    const [target, setTarget] = useState<HTMLElement|null>(null);

    return (
        <>
            <div style={{fontSize: "1rem", color: grey["700"]}} onMouseEnter={e => setTarget(e.currentTarget)} onMouseLeave={e => setTarget(null)}>
                {props.icon}
            </div>
            {target && <Popover
                sx={{ pointerEvents: 'none' }}
                open anchorEl={target} onClose={() => setTarget(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <div style={{padding: 8, maxWidth: 300, fontSize: "0.8rem"}}>
                    {props.value}
                </div>
            </Popover>}
        </>
    )
}

function SortInfo(props: {
    name: string;
}) {
    const ctx = useContext(TablePersonalizeContext);
    if(ctx.sortBy?.column !== props.name) return null;

    return (
        <div style={{flex: 0}}>
            {ctx.sortBy?.reverse ? <ArrowUpward fontSize="inherit" /> : <ArrowDownward fontSize="inherit" /> }
        </div>
    )
}

function DraggableDivider(props: {
    column: Column<any>;
    width: number;
}) {
    const ctx = useContext(TablePersonalizeContext);

    return (
        <DraggableDividerInner onMouseDown={e => {
            const width = props.width;

            const cfg = ctx.lookupByName(props.column.name, props.column)

            const startX = e.clientX;

            const move = (e: MouseEvent) => {
                const newWidth = Math.max(width + (e.clientX - startX), 50);

                const byName = Object.assign({}, ctx.columns, {
                    [props.column.name]: Object.assign({}, cfg, {
                        width: newWidth,
                    })
                })

                ctx.onChange({
                    byName
                })
            }

            const up = () => {
                document.body.removeEventListener("mousemove", move)
                document.body.removeEventListener("mouseup", up)
            }

            document.body.addEventListener("mouseup", up)
            document.body.addEventListener("mousemove", move)
        }}>&nbsp;</DraggableDividerInner>
    )
}

const DraggableDividerInner = styled("div")(props => ({
    width: 4,
    borderRight: "1px solid " + grey["300"],
    cursor: "ew-resize",

    "&:hover": {
        borderRight: "1px solid " + props.theme.palette.primary.main,
    }
}))

const HeaderCellInner = styled("div")(() => ({
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: 2,
    paddingTop: 2,
    lineHeight: "1em",
    border: "1px solid transparent",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: grey["100"],
    }
}))

const cell = css({
    position: "relative",
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: 2,
    paddingTop: 2,
    lineHeight: "1em",
    textAlign: "left",
    wordBreak: "break-all",
    fontSize: "0.8rem",

    border: "1px solid transparent",
    borderRight: "1px solid " + grey["300"],
})

const rangeSelectedCell = css({
    border: "1px solid " + blue["600"],
})

const standardCell = css({
    border: "1px solid transparent",
    borderRight: "1px solid " + grey["300"],
})

const editCell = css({
    position: "relative",
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: 3,
    paddingTop: 3,
    fontSize: "0.8rem",
    cursor: "pointer",
    lineHeight: "1em",
    wordBreak: "break-all",

    "&:hover": {
        border: "1px solid " + blue["600"],
    }
})

function isIgnoreClicks(value: HTMLElement | null) {

    while(value) {
        if (value.getAttribute("data-ignore-clicks")) return true;
        value = value.parentElement;
    }

    return false;
}

const VSeparator = styled("div")(() => ({
    position: "absolute",
    top: 0,
    bottom: 20,
    zIndex: 10,
    borderLeft: separationBorder,
}))

function OuterWrapper(props: PropsWithChildren<{}>) {
    const wrapperRef = useRef<HTMLDivElement|null>(null);
    const ctx = useContext(EditContext)
    const ctxRef = useSyncedRef(ctx)

    const isWithinWrapper = useCallback((el: HTMLElement|null) => {
        if(!wrapperRef.current) return true;

        let cur = el;

        while(!!cur && cur !== document.body) {
            if (cur === wrapperRef.current) {
                return true;
            }

            cur = cur.parentElement;
        }

        return false;
    }, []);

    const hasPopout = ctx.hasPopout;
    console.log("has-popout", hasPopout)

    const isDetatched = useCallback((target: HTMLElement) => {
        let cur: HTMLElement|null = target;
        while(cur !== null) {
            if(cur === document.body) return false;
            cur = cur.parentElement;
        }

        return true;
    }, []);

    useEffect(() => {
        if(hasPopout) return;
        const clickHandler = (e: MouseEvent) => {
            if(!ctxRef.current.onSelect.lastValue && !ctxRef.current.onRange.lastValue) return;
            const target = e.target;

            // @ts-ignore
            if(isDetatched(target)) return;
            if(isWithinWrapper(e.target as any)) return;
            if(isIgnoreClicks(e.target as any)) return;

            if(ctxRef.current.onSelect.lastValue) {
                ctxRef.current.onSelect.emit(null);
            }

            if(ctxRef.current.onRange.lastValue) {
                ctxRef.current.onRange.emit(null);
            }
        }

        document.body.addEventListener("click", clickHandler)
        return () => {
            document.body.removeEventListener("click", clickHandler)
        }
    }, [ctxRef, isWithinWrapper, hasPopout, isDetatched]);

    return (
        <div className={outerWrapperCss} ref={wrapperRef}>
            {props.children}
        </div>
    )
}

const outerWrapperCss = css({
    position: "relative",
    flex: 1,
    display: "flex",
    overflow: "hidden",

    ["& ." + tableRow + ":last-child ." + rowBorderBottom]: {
        borderBottom: "1px solid " + grey["200"],
    },
})

const FilterCell: any = React.memo(function FilterCell<T extends TID>(props: {
    column: Column<T>
    width: number;
    hidden: boolean;
    autoGrow?: boolean;
}) {
    const width = props.width;

    if(props.hidden) {
        return null;
    }

    const filter = props.column.filter;
    if(!filter) {
        return (
            <div style={{
                flexShrink: 0,
                flexBasis: width,
                width: props.autoGrow ? undefined : width,
                flex: props.autoGrow ? 1 : undefined,
                borderRight: "1px solid " + grey["300"]
            }} />
        );
    }

    return (
        <div style={{
            width: props.autoGrow ? undefined : width,
            minWidth: width,
            flex: props.autoGrow ? 1 : undefined,
            borderRight: "1px solid " + grey["300"]
        }}>
            <FilterCellInner column={props.column} filter={filter} />
        </div>
    )
});


function FilterCellInner<T extends TID>(props: {
    column: Column<T>,
    filter: (input: T, value: string) => boolean;
}) {
    const ctx = useContext(FilterContext);
    const editCtx = useContext(EditContext)
    const [value, setValue] = useState("");

    const valueRef = ctx.valueRef;

    useEffect(() => {
        if(value.trim() === "") return;

        const valueLC = value.trim().toLowerCase();
        valueRef[props.column.name] = (input: T) => props.filter(input, valueLC);

        return () => {
            delete valueRef[props.column.name];
        }
    }, [props, props.column.name, value, valueRef])

    useEffect(() => {
        ctx.onClear.subscribe(() => {
            setValue("")
        })
    }, [ctx.onClear, props.column.name, valueRef])

    if(props.column.renderFilter) {
        return (
            <>
                {props.column.renderFilter({
                    value: value,
                    onChange: setValue,
                })}
            </>
        );
    }

    return (
        <FilterWrapper>
            <FilterInput
                type="text" value={value} placeholder="Filter"
                onFocus={e => {
                    editCtx.onSelect.emit(null)
                }}
                onChange={e => setValue(e.target.value)}
            />
        </FilterWrapper>
    );
}

const FilterWrapper = styled("div")(() => ({
    display: "flex",
    width: "100%",
}))

const FilterInput = styled("input")(() => ({
    flex: 1,
    width: "auto",
    minWidth: 0,
    border: "none",
    borderRadius: 0,
    padding: 2,
    paddingLeft: 4,
    paddingRight: 4,
    margin: 1,
}))

const flexDataRowClass = css({
    display: "flex",
    flexWrap: "nowrap",
    fontSize: "0.9rem",
    borderTop: "1px solid " + grey["300"],
    background: "white",
    width: "100%",
    borderRight: "1px solid " + grey["300"],
})

const FlexHeaderRow = styled("div")(() => ({
    display: "flex",
    alignItems: "stretch",
    flexWrap: "nowrap",
    fontWeight: "600",
    width: "100%",
    fontSize: "0.85rem",
    borderTop: "1px solid " + grey["300"],
    background: "white",
}))

export type Insert = {
    alignX?: "left" | "right";
    shortcut?: { // default ctrl + '+'
        ctrl: true;
        key: string;
    };
    buttonText?: string;
    options?: (props: {}) => any;
    modal(onDone: () => {}): any;
}

const Footer = React.memo(function Footer(props: {
    insert?: Insert;
}) {
    const {onFilter, onClear} = useContext(FilterContext);
    const [detail, setDetail] = useState<FilterDetail>();

    const {listenAll} = useContext(CheckboxContext);
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        const sub = listenAll(list => setSelected(list.length));
        return () => sub.cancel();
    }, [listenAll])

    useEffect(() => {
        const sub = onFilter.subscribeAndFireLast(setDetail);
        return () => sub.cancel();
    }, [onFilter])

    const [cellsSelected, setCellsSelected] = useState(0);

    const {onRange} = useContext(EditContext);
    const {onCanUndo, undo} = useContext(UndoContext);
    useEffect(() => {
        const sub = onRange.subscribeAndFireLast(v => {
            if(!v) {
                setCellsSelected(0);
                return;
            }

            const nColumns = Math.abs(v.fromIndex - v.toIndex) + 1;
            setCellsSelected(v.ids.length * nColumns);
        });
        return () => sub.cancel();
    }, [onRange])

    const canUndo = EventEmitter.reactValue(onCanUndo);

    const tbl = useContext(TableContext);

    if(!detail) return null;

    return (
        <div style={{
            fontSize: "0.8rem",
            paddingLeft: 4,
            borderTop: "1px solid " + grey["300"],
            backgroundColor: grey["100"],
            zIndex: 12, // for fixed-column separator
            position: "relative",
        }}>
            <Grid container spacing={1}>
                {detail.visible !== detail.total ?
                    <Grid item style={{display: "flex"}}>
                        <div style={{paddingRight: 8}}>{detail.visible} of {detail.total} records</div>
                        <Link onClick={() => onClear.emit(true)}>Clear Filter</Link>
                    </Grid> :
                    <Grid item style={{display: "flex"}}>
                        {detail.total} records
                    </Grid>
                }
                {selected > 0 && <Grid item>
                    {selected} rows selected
                </Grid>}
                {cellsSelected > 0 && <Grid item>
                    {cellsSelected} cells selected
                </Grid>}
                <Grid item>
                    <Link onClick={() => tbl.reload(false)}>Refresh</Link>
                </Grid>
                <Grid item>
                    <Link disabled={!canUndo} onClick={undo}>Undo</Link>
                </Grid>
            </Grid>
            {props.insert && <TableInsert {...props.insert} />}
        </div>
    );
});

const Link = styled("div")((props: {disabled?: boolean}) => ({
    color: props.disabled ? grey["700"] : "blue",
    textDecoration: "underline",
    cursor: props.disabled ? "initial": "pointer",

    "&:hover": props.disabled ? {} : {
        opacity: 0.9,
    }
}))