import {SubAPI} from "./SubAPI";
import {DateString} from "./Projects";

export class ProjectTimeline extends SubAPI {
    list(props: {
        project: number;
    }) {
        return this.fetcher.get<TimelineItem[]>("/api/project-timeline", props)
    }

    upsert(props: Partial<TimelineItem>) {
        return this.fetcher.post<TimelineItem>("/api/project-timeline/item", props);
    }

    queryProducts(props: {
        project: number;
        query: ProductQuery;
    }) {
        return this.fetcher.post<ProductQueryResult[]>("/api/product-query/run", props);
    }
}

type ProductQueryResult = {
    inventoryRequest: number;
    opening?: number;
    openingName?: string;

    kind: string;
    product: number
    productName?: string
    productCode?: string
    productCategoryName?: string;
    qty: number;
    neededBy?: DateString;
}


export type TimelineItem = {
    id: number;
    project: number;
    name: string;

    date: string;
    archived: boolean

    useAsReleaseDateForQuery: null | ProductQuery
}

export type ProductQuery = {
    type?: string[];
    openingNames?: string[];
    openingLocationOne?: string[];
    openingLocationTwo?: string[];
    floor?: string[];
    doorSeries?: string[];
    frameSeries?: string[];
    exterior?: string[];
}