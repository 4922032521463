import {SubAPI} from "./SubAPI";
import {OpeningType2} from "./Products";

export type LibraryType = "hardware" | "div10/wr-accessory"

export class Library extends SubAPI {
    clear(props: {
        id: number
    }) {
        return this.fetcher.post("/api/hardware-library/clear", props)
    }

    get(props: {
        project: number;
        alternative?: number
        type: LibraryType;
    }) {
        return this.fetcher.get<HWLibrary|null>("/api/hardware-library", props)
    }

    copyFromProject(props: {
        from: number;
        fromQuoteAlternative: number | null;
        to: number
        toQuoteAlternative?: number | null
        type: LibraryType;
    }) {
        return this.fetcher.post("/api/hardware-library/copy", props);
    }

    upsert(props: Partial<HWLibrary>) {
        return this.fetcher.post<HWLibrary>("/api/hardware-library", props);
    }

    upsertShortcode(props: ShortCode) {
        return this.fetcher.post<ShortCode>("/api/hardware-library/shortcode", props);
    }

    getShortCodes(input: {
        project: number,
        alternative?: number
        search?: string;
        type: LibraryType;
        previewChangeset?: number;
    }) {
        return this.fetcher.get<ShortCode[]>("/api/hardware-library/shortcodes", input)
    }
}

export type HWLibrary = {
    id: number;
    project: number;
    quoteAlternative?: number;
    libraryType: LibraryType;
}

export type ShortCode = {
    id: number;
    value: string;
    description: string;
    openingType?: "Single" | "Pair" | ""
    project: number;
    libraryType?: LibraryType; // helps backend infer .library using .project
    quoteAlternative?: number;
    library: number;
    archived: boolean;
    backup?: ShortCode;

    items: ShortCodeItem[] | null;
}

export type ShortCodeItem = {
    id: number;
    shortcode: number;

    product: number;
    productName: string;
    manufacturer: number;
    manufacturerName: string;
    supplier: number;
    supplierName: string;
    defaultHwGroup: OpeningType2;
    defaultProvidedByOthers: boolean;

    productCode: string;
    finish: string;
    hasDimension: boolean;
    dimHeight: string;
    productIsAutoQtyHinge: boolean;

    qty: number;

    backup?: ShortCodeItem;
}