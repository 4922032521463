import React, {PropsWithChildren, useEffect} from "react";
import {createTheme, IconButton, styled, ThemeProvider} from "@mui/material";
import {Sidebar} from "./Sidebar";
import {CurrentUser} from "./CurrentUser";
import {TableSelectionContextProvider} from "./scroller/Table";
import {HeaderTasks} from "./tasks/HeaderTasks";
import West from "@mui/icons-material/West";
import {Link, useNavigate} from "react-router-dom";
import {amber, green, grey} from "@mui/material/colors";
import {UndoProvider} from "./scroller/GlobalUndo";
import {TestingBanner} from "./TestingBanner";
import {useSessionStorage} from "react-use";
import {BackgroundErrorWatcher} from "./BackgroundErrorWatcher";

const AppBar = styled("div")<{
    color?: string;
}>((props) => ({
    backgroundColor: props.color || props.theme.palette.primary.main,
    width: "100%",
    padding: 6,
    flexGrow: 0,
    flexBasis: 1,
    boxShadow: "0 0 3px 0px #000000a1",
    display: "flex",
    alignItems: "center",
    zIndex: 1,
}));

const Outer = styled("div")(props => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}))

const Row = styled("div")(props => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "stretch",
    flex: 1,
    overflow: "auto",
}))

const Body = styled("div")(props => ({
    backgroundColor: props.theme.palette.primary.light,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}))

export function Container(props: PropsWithChildren<{
    topCenter?: JSX.Element
    color?: string;
    noSidebar?: boolean
    focused?: boolean;
    noScroll?: boolean;
}>) {

    let color = props.color;
    if(!color && props.focused) {
        color = grey["800"];
    }

    let noSidebar = props.noSidebar;
    if(props.focused) {
        noSidebar = true;
    }

    return (
        <Outer style={{
            overflow: props.noScroll ? "hidden" : undefined,
        }}>
            <UndoProvider>
                <TableSelectionContextProvider>
                    <TestingBanner />
                    <AppBar color={color}>
                        <img src="/images/handle-white.png" style={{paddingLeft: 8, height: 20, marginBottom: -3}} alt="Logo" />
                        <div style={{paddingLeft: 8, color: "white", fontWeight: "bold"}}>
                            Handle
                        </div>
                        <div style={{flex: 1}} />
                        {props.topCenter}
                        <div style={{flex: 1}} />
                        <HeaderTasks />
                        <div style={{width: 8}} />
                        <CurrentUser />
                    </AppBar>
                    <Row style={{
                        overflow: props.noScroll ? "hidden" : undefined,
                    }}>
                        {noSidebar ? null : <Sidebar />}
                        <Body style={{
                            overflow: props.noScroll ? "hidden" : undefined,
                        }}>
                            {props.children}
                        </Body>
                    </Row>
                    <BackgroundErrorWatcher />
                </TableSelectionContextProvider>
            </UndoProvider>
        </Outer>
    );
}

const mobileTheme = createTheme({
    palette: {
        secondary: amber,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1.4rem"
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: "2rem"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: "1.2rem",
                }
            }
        }
    }
})

const mobileThemeGreen = createTheme({
    palette: {
        primary: green,
        secondary: amber,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1.4rem"
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: "2rem"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: "1.2rem",
                }
            }
        }
    }
})

export function MobileContainer(props: PropsWithChildren<{
    topLeft?: any;
    topRight?: any;
    back?: string;
    color?: "green" | null;
}>) {
    const navigate = useNavigate();

    // prevent flicker when transitioning screens (null means don't change)
    const [sessionColor, setSessionColor] = useSessionStorage<string>("mobile-theme-color")
    const [color, setColor] = React.useState(props.color || sessionColor);
    const propsColor = props.color;

    useEffect(() => {
        if(propsColor === null) return;
        setColor(propsColor || "");
        setSessionColor(propsColor || "");
    }, [propsColor, setSessionColor]);

    return (
        <ThemeProvider theme={color === "green" ? mobileThemeGreen : mobileTheme}>
            <Outer>
                <TableSelectionContextProvider>
                    <AppBar>
                        {props.back ?
                            <div style={{width: 60}}>
                                <IconButton style={{color: "white"}} onClick={() => navigate(props.back || "/")}>
                                  <West />
                                </IconButton>
                            </div>
                            : <Link to="/"><img src="/images/handle-white.png" style={{paddingLeft: 8, height: 20, marginBottom: -3}} alt="Logo" /></Link>
                        }

                        <div style={{color: "white", fontSize: "1.2rem", fontWeight: "bold"}}>{props.topLeft}</div>
                        <div style={{flex: 1}} />
                        {props.topRight}
                        <div style={{width: 60, display: "flex", justifyContent: "flex-end"}}>
                            <CurrentUser />
                        </div>
                    </AppBar>
                    <Body>
                        {props.children}
                    </Body>
                </TableSelectionContextProvider>
            </Outer>
        </ThemeProvider>
    )
}

export function PopoutContainer(props: PropsWithChildren<{}>) {
    return (
        <TableSelectionContextProvider>
            {props.children}
        </TableSelectionContextProvider>
    )
}