import { SubAPI } from "./SubAPI";
import {Paginated} from "./Users";
import {DateString} from "./Projects";
import {Cents} from "nate-react-api-helpers";
import {Moment} from "moment";
import {TDiv10Type} from "../pages/project/shopdrawing/div10/Div10Tabs";
import {Div10Category} from "./Pricing";
import {POHwPrepItem} from "../pages/logistics/purchasing/pdf/FrameDetails";
import {DescriptionInput} from "../pages/logistics/purchasing/toSource/SubmitButton";

export class Logistics extends SubAPI {

    listInventoryRequest(input: {
        project: number
        offset?: number;
        state?: string;
        kind?: string; // e.g. hardware
        category?: "opening" | "div10" | "door-hm" | "frame-hm" | "door-wd" | "hardware"
        nonStockingForRelease?: number;
    }) {
        return this.fetcher.get<Paginated<InventoryRequest>>("/api/logistics/inventory-requests", input)
    }

    updateInventoryRequest(input: InventoryRequest) {
        return this.fetcher.post("/api/logistics/inventory-request", input)
    }

    setInventoryStocking(input: {
        product: number;
        stocking: boolean;
    }) {
        return this.fetcher.post("/api/logistics/inventory-item/set-stocking", input)
    }

    bulkUpdateRequestDueDates(input: {
        ids: number[],
        due: Moment|null,
    }) {
        return this.fetcher.post("/api/logistics/inventory-requests/bulk-update-due-date", input)
    }

    listPurchaseOrders(input: {
        offset?: number;
        search?: string;
        project?: number;
        supplier?: number;
        outstanding?: boolean;
        forRouting?: boolean;
        readyForPickup?: boolean;
        readyForDelivery?: boolean;
        forPayables?: boolean;
        forDoorManufacture?: boolean
        includeInActive?: boolean;
    }) {
        return this.fetcher.get<Paginated<PurchaseOrder>>("/api/logistics/purchase-orders", input)
    }

    getPO(input: {id: number}) {
        return this.fetcher.get<PurchaseOrder>("/api/logistics/purchase-order", input)
    }

    updatePurchaseOrder(input: PurchaseOrder) {
        return this.fetcher.post<PurchaseOrder>("/api/logistics/purchase-order", input)
    }

    updatePayable(input: PurchaseOrder) {
        return this.fetcher.post<PurchaseOrder>("/api/logistics/payable", input)
    }

    listPurchaseOrderItems(input: {
        offset?: number;
        purchaseOrder: number;
        type: "payable" | "purchase-order"
    }) {
        return this.fetcher.get<Paginated<PurchaseOrderItem>>("/api/logistics/purchase-order/items", input)
    }

    listLogisticsPurchaseOrderItems(input: {
        offset?: number;
        purchaseOrder: number;
        forLogistics: boolean; // true;
    }) {
        return this.fetcher.get<Paginated<PurchaseOrderItem & {product: number}>>("/api/logistics/purchase-order/items", input)
    }

    updatePurchaseOrderItem(input: Omit<PurchaseOrderItem, "logisticsQtyOutstanding">) {
        return this.fetcher.post("/api/logistics/purchase-order/item", input)
    }

    listToSourceItems(props: {
        offset?: number;
        supplier?: number;
        type?: "hardware"|"door"|"frame"|"div10"|"frame-anchor"|"component"
        subType?: TDiv10Type;
        sourceMode?: SourceMode;
        distributionMode?: DistributionMode
    }) {
        return this.fetcher.get<Paginated<ToSourceItem>>("/api/logistics/items-to-source", props)
    }

    listInventory(props: {
        offset?: number;
        search?: string;
        kind?: string;
        subKind?: "hardware" | "door" | "frame" | "div10"| "component" | "frame-anchor"
        expandGroup?: number | null;
        expandAllGroups?: boolean;
    }) {
        return this.fetcher.get<Paginated<InventoryItem>>("/api/logistics/inventory-items", props)
    }

    listProjectInventory(props: {
        offset?: number;
        search?: string;
        subKind?: "hardware" | "door" | "frame" | "div10" | "component"
    }) {
        return this.fetcher.get<Paginated<{id: number} & InventoryItem>>("/api/logistics/project-inventory-items", props)
    }

    nonStockingInventoryItems(props: {
        offset?: number;
        search?: string;
        subKind?: "hardware" | "door" | "frame" | "div10" | "component"
    }) {
        return this.fetcher.get<Paginated<{id: number} & InventoryItem>>("/api/logistics/non-stocking-inventory-items", props)
    }

    updateInventory(props: InventoryItem) {
        return this.fetcher.post<InventoryItem>("/api/logistics/inventory-item", props)
    }

    listInventoryToRestock(props: {
        offset?: number;
        search?: string;
        belowMin?: boolean;
    }) {
        return this.fetcher.get<Paginated<InventoryItem>>("/api/logistics/inventory-items/restocking", props)
    }

    dashSummary() {
        return this.fetcher.get<DashSummary>("/api/logistics/dash-summary", {})
    }

    updateRouteCalendar(input: {
        srcStop: number;
        dstRoute?: number;
        dstCreateRouteForDate?: string;
        dstSortOrder?: number;
    }) {
        return this.fetcher.post("/api/logistics/route/update-calendar", input)
    }

    updateRoute(props: DriverRoute) {
        return this.fetcher.post<DriverRoute>("/api/logistics/route", props)
    }

    listRoutes(props: {
        active?: boolean;
        offset?: number;
        search?: string;
    }) {
        return this.fetcher.get<Paginated<DriverRoute>>("/api/logistics/routes", props)
    }

    getRoutesForWeek(props: {
        weekOf: string;
    }) {
        return this.fetcher.get<DriverRoute[]>("/api/logistics/routes-for-week", props)
    }

    getRoute(props: {id: number}) {
        return this.fetcher.get<DriverRoute>("/api/logistics/route", props)
    }

    listRouteStops(props: {
        route: number
    }) {
        return this.fetcher.get<RouteStop[]>("/api/logistics/route/stops", props)
    }

    upsertStop(input: RouteStop) {
        return this.fetcher.post("/api/logistics/route/stop", input)
    }

    stopViewedByDriver(input: {id: number}) {
        return this.fetcher.post("/api/logistics/route/stop/viewed-by-driver", input)
    }

    addRouteItems(input: {
        route: number;
        items: {
            pickupPo?: number | null;
            pickupDelivery?: number | null;
            dropProject?: number | null;
            stop?: number | null;
            manufacturer?: number | null;
            customStop?: string;
            customGroups?: string[];
            customStopAddressStreet?: string;
            customStopAddressCity?: string;
            customStopAddressPostal?: string;
        }[]
    }) {
        return this.fetcher.post("/api/logistics/route/add-items", input)
    }

    listRoutableItems(input: {
        offset?: number;
        type: "pickup" | "drop"
    }) {
        return this.fetcher.get<Paginated<DriverRoutePendingItem>>("/api/logistics/route/items-to-move", input)
    }

    reRouteGroup(input: {
        pickupGroupId: number;
        purchaseOrderItemsToWarehouse: number[]
    }) {
        return this.fetcher.post("/api/logistics/route/re-route-group", input)
    }

    upsertStopGroup(input: RouteStopGroup) {
        return this.fetcher.post("/api/logistics/route/stop-group", input)
    }

    upsertStopGroupItems(input: {
        group: number;
        items: RouteStopGroupItem[]
    }) {
        return this.fetcher.post("/api/logistics/route/stop-group-items", input)
    }

    listSplitRouteItemsToSkip(input: {
        atGroup: number;
    }) {
        return this.fetcher.get<RouteStopGroupItem[]>("/api/logistics/route/split-route-items-to-skip", input)
    }

    sendRouteMessage(input: {
        route: number;
        content: string;
    }) {
        return this.fetcher.post("/api/logistics/route/messages/send", input)
    }

    getRouteMessages(input: {
        route: number;
    }) {
        return this.fetcher.get<RouteMessage[]>("/api/logistics/route/messages", input)
    }

    listDamagedProducts(props: {
        includeReviewed: boolean;
        project?: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<DamageReport>>("/api/logistics/damaged-products", props);
    }

    updateDamageReport(input: DamageReport) {
        return this.fetcher.post<{}>("/api/logistics/damaged-product", input);
    }

    listWasteProducts(props: {
        includeReviewed: boolean;
        project?: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<WasteReport>>("/api/logistics/waste/list", props);
    }

    updateWasteReport(props: WasteReport) {
        return this.fetcher.post<{}>("/api/logistics/waste", props);
    }

    listStockCounts(props: {
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<StockCount>>("/api/logistics/stock-counts", props)
    }

    upsertStockCount(props: StockCount) {
        return this.fetcher.post<StockCount>("/api/logistics/stock-count", props)
    }

    startStockCount(props: {}) {
        return this.fetcher.post<{id: number}>("/api/logistics/stock-count/start", props)
    }

    completeStockCount(props: {
        id: number;
    }) {
        return this.fetcher.post("/api/logistics/stock-count/complete", props)
    }

    listStockCountItems(props: {
        offset?: number;
        search?: string;
        stockCount: number;
        kind?: "hardware" | "door" | "frame" | "div10"| "component" | "frame-anchor"
    }) {
        return this.fetcher.get<Paginated<StockCountItem>>("/api/logistics/stock-count/items", props)
    }

    updateStockCountItem(props: StockCountItem) {
        return this.fetcher.post("/api/logistics/stock-count/item", props)
    }

    upsertStockOrder(input: StockOrder) {
        return this.fetcher.post<StockOrder>("/api/logistics/stock-order", input)
    }

    listStockOrders(props: {
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<StockOrder>>("/api/logistics/stock-orders", props)
    }

    startStockOrder(props: {}) {
        return this.fetcher.post<{id: number}>("/api/logistics/stock-order/start", props)
    }

    completeStockOrder(props: {
        id: number;
    }) {
        return this.fetcher.post("/api/logistics/stock-order/complete", props)
    }

    listStockOrderItems(props: {
        offset?: number;
        search?: string;
        stockOrder: number;
        kind?: "hardware" | "door" | "frame" | "div10"| "component" | "frame-anchor"
    }) {
        return this.fetcher.get<Paginated<StockOrderItem>>("/api/logistics/stock-order/items", props)
    }

    updateStockOrderItem(props: StockOrderItem) {
        return this.fetcher.post("/api/logistics/stock-order/item", props)
    }

    logGetSummary(props: {
        inventoryRequest?: number;
        miscProduct?: number;
        opening?: number;
    }) {
        return this.fetcher.get<LogisticsSummary[]>("/api/logistics/inventory-request-log/summary", props)
    }

    logGetItems(props: {
        inventoryRequest?: number;
        miscProduct?: number;
        opening?: number;
        offset?: number;
    }) {
        return this.fetcher.get<Paginated<LogisticsLogItem>>("/api/logistics/inventory-request-log/list", props)
    }
}

export type RouteMessage = {
    id: number;
    route: number;
    createdAt: DateString;
    createdBy: number;
    createdByName: string;
    content: string;
    viewedAt: DateString | null;
}

export type DriverRoute = {
    id: number;
    label: string;
    createdAt: DateString;
    date: DateString;
    completed: boolean;
    archived: boolean;

    stops?: RouteStop[]
}

export type RouteStop = {
    id: number;
    manufacturer?: number;
    manufacturerName?: string;

    project?: number;
    projectName?: string;
    projectUseAltAddress?: number;
    projectUseAltAddressName?: string;
    projectUseContractorAddress?: number;

    route: number;
    customTextStop?: string;
    sortOrder: number;
    tykelWarehouse: boolean;

    contacts: RouteContact[];
    addressStreet: string | null;
    addressCity: string | null;
    addressPostal: string | null;
    addressProvince: string | null;

    // for custom stops
    customTextStopAddressStreet: string | null;
    customTextStopAddressCity: string | null;
    customTextStopAddressPostal: string | null;
    customCompanyForContacts: number | null;

    noteToDriver: string;

    driverViewed: boolean;
    driverAt?: boolean;

    groups: RouteStopGroup[] | null
}

export type RouteContact = {
    id: number; // from "contact" database table
    name: string;
    title: string;
    email: string;
    phone: string;
}

export type RouteStopGroup = {
    id: number;
    name: string;
    pickupPoNumber: string | null;
    pickupPurchaseOrder?: number;
    dropoffPoNumber: string | null;
    dropoffPurchaseOrder?: number;
    delivery?: number;
    deliveryProjectName?: string;

    deliveryReceiptBy: string;
    deliveryReceiptDoc?: number;
    deliveryReceiptTimestamp?: DateString;

    deliveryProofCompletedAt?: DateString;
    deliveryProofCompletedBy?: number;

    customTextGroup?: string;
    routeStop: number;
    archived: boolean;
    completed: boolean;

    items: RouteStopGroupItem[] | null
}

export type RouteStopGroupItem = {
    id: number;
    name: string;
    qty: number;
    qtyReceived: number | null;
    qtyDamaged: number | null;
    routeStopGroup: number;
    uom: string;
    archived: boolean;

    purchaseOrderItem?: number;
    deliveryItem?: number;
    deliveryBox?: number;
    dropoffAtSiteInNumStops?: number;

    openingMeta?: {name: string}[];
    boxDamageDetail?: Partial<RouteStopGroupItem>[];
}

export type ProductPreview = {
    name: string;
    qty: number;

    qtyReceived?: number | null;
    qtyDamaged?: number | null;
    openingMeta?: {name: string}[];

    boxId?: number;
    boxLabel?: string;
    boxType?: string;
}

export type DriverRoutePendingItem = {
    id: number;
    pickupPo: number | null;
    pickupPoNumber: string | null;
    pickupCompany: number | null;
    pickupCompanyName: string | null;
    pickupDelivery: number | null;
    dropProject: number | null;
    dropProjectName: string | null;
    qty: number;
    contents: {
        name: string,
        qty: number,
        boxId?: number;
        boxLabel?: string;
        boxType?: string;
    }[];
    archived: boolean;
}

export type LogisticsSummary = {
    lastUpdated: DateString;

    po: {
        id: number;
        poNumber: string;
        status: string;
    };

    delivery: {
        id: number;
        name: string;
        status: string;
    };

    route: {
        id: number;
        name: string;
        completed: boolean;
    };
}

export type LogisticsLogItem = {
    id: number;
    inventoryRequest: number;
    opening: number;
    createdAt: DateString;
    createdBy: number;
    createdByName: string;
    description: string;
    purchaseOrder: number | null;
    poNumber: string | null;
    delivery: number | null;
    route: number | null;
    status: string
}

export type StockCount = {
    id: number;
    createdBy: number;
    createdByName: string;
    completed: boolean;
    updatedAt: DateString;
    archived: boolean;
    createdAt: DateString;

    qtyChanged: number;
    qtyChecked: number;
    qtySkipped: number;
    lossCents: Cents;
    gainCents: Cents;
}

export type StockCountItem = {
    id: number;
    product: number;

    productName: string;
    productCode: string;
    finishCode: string;

    manufacturer: number;
    manufacturerName: string;

    qtyCounted: number;
    qtyExpected: number;
    note: string;
    checked: boolean;
    updatedAt: DateString;
    stockCount: number;

    changeCostCents: Cents;

    qty: number;
    stockMinQty: number;
    idealQty: number;
    warnReorder: boolean;
    warnLow: boolean;
}

export type StockOrder = {
    id: number;
    createdBy: number;
    createdByName: string;
    completed: boolean;
    updatedAt: DateString;
    archived: boolean;
    createdAt: DateString;

    qtyChecked: number;
    qtySkipped: number;
}

export type StockOrderItem = {
    id: number;

    description: string;
    product: number;
    baseProduct?: number;

    overrideSupplier?: number;

    productName: string;
    productCode: string;

    manufacturer: number;
    manufacturerName: string;

    qtyIdeal: number;
    qtyMinStock: number;
    qtyNeeded: number;
    qtyPendingPo: number;
    qtyReserved: number;
    qtyStock: number;
    qtyToOrder: number;

    note: string;
    checked: boolean;
    updatedAt: DateString;
    stockCount: number;
}

export type DistributionMode = "all" | "focused"

export type WasteReport = {
    id: number;
    productName: string;
    logisticsDirective: string; // "warehouse" | "leave-at-supplier"

    reviewed: boolean;

    supplier: number | null;
    supplierName: string | null;
    poNumber: string | null;
    purchaseOrder: number | null;

    createdAt:     DateString;
    createdByName: string
    createdBy:     number;

    updatedAt: DateString;
    updatedBy: number;

    archived: boolean;

    qty: number;
    unitCostCents: number;
    markupCents: number | null;
    markupPercent: number | null;
    extendedPrice: number;

    note: string;
}

export type SourceMode = "to-order" | "stock"

export type DamageReport = {
    id: number;

    createdAt:     DateString;
    createdBy:     number;
    createdByName: string

    updatedAt: DateString;

    product:             number;
    productName:         string
    qty:                 number;
    receivedUnitPrice:   Cents;
    purchaseOrder:       number;
    purchaseOrderNumber: string
    supplier: string;

    project?: number;

    wePayPercent?: number | null;
    wePayPerUnitCents:   number;
    theyPayPerUnitCents: number;
    reviewed:     boolean;
    usableQty?:    number;

    archived: boolean;
}

type DashSummary = {
    qtyToOrder: number;
    activeOrderCount: number;
    qtyToRestock: number;
    centsOrdered7d: number;
    centsOrdered7dPrev: number;
    centsPicked7d: number;
    centsPicked7dPrev: number;
    centsDelivered7d: number;
    centsDelivered7dPrev: number;
}

export type ToDeliverItem = {
    id: number;
    source: string;

    product: number;
    name:             string;
    productCode:      string;
    dimensions:       string;

    doorSeries?: string;
    doorMaterial?: string;
    doorFinish?: string;
    doorGauge?: string;
    doorElevation?: string;
    doorCore?: string;
    doorSeam?: string;
    doorHanding?: string;
    doorLabel?: string;

    frameSeries?: string;
    frameMaterial?: string;
    frameGauge?: string;
    frameScreenElev?: string;
    frameProfile?: string;
    frameJambDepth?: string;
    frameConstruction?: string;
    frameHanding?: string;
    frameHardwarePreps?: string;
    frameLabel?: string;
    frameType?: string;

    qty: number;

    manufacturer: number;
    manufacturerName: string;

    project: number;
    projectName: string;

    doorName?: string;

    inventoryRequestIds: number[];

    description?: string;
    dimWidth?: string;
    dimHeight?: string;
    dimLength?: string;
    finish?: string;
    div10CornerGuardFinish?: string;
    div10CornerGuardType?: string;
    div10Description?: string;
    div10LockerBaseTrim?: string;
    div10LockerCoating?: string;
    div10LockerEndPanels?: boolean;
    div10LockerSloped?: boolean;
    div10LockerTiers?: string;
    div10MailboxLoading?: string;
    div10MailboxMounting?: string;
    div10MailboxParcelUnits?: number;
    div10MailboxTenantUnits?: number;
    div10Note?: string;
    div10PartitionCoating?: string;
    div10Room?: string;
}

export type InventoryItem = {
    id: number;
    qty: number;
    stockMinQty: number;
    idealQty: number;
    isStocking: boolean;
    archived: boolean;

    project: number;
    projectName: string;
    door: string;

    product: number;

    name:             string;
    productCode:      string;
    finishCode:       string;
    dimensions:       string;

    isGroup: boolean;
    baseProduct?: number;

    warnReorder: boolean;
    warnLow: boolean;

    manufacturer: number;
    manufacturerName: string;

    belowMinSince?: DateString;

    projects: {
        projectId: number;
        projectName: string;
        inventoryRequestId: number; // pick a random inventory request
        qty: number;
    }[]
}

export type ToSourceItem = {
    id: number;

    qty: number;
    stockMinQty: number | null;
    idealQty: number | null;
    stockQty: number | null;
    reservedQty: number | null;
    purchasedQty: number | null;

    kind: string;

    projectMeta: {
        project: number;
        projectName: string;
        qty: number;
        neededBy: DateString;
    }[]

    source: string;

    product: number;
    name: string;
    description: string;
    productCode: string;
    finish: string
    dimensions: string;
    dimWidth: string;
    dimHeight: string;
    dimLength: string;
    partOfFrame: boolean;

    frameAnchorName?: string;
    frameAnchorDetail?: ToSourceItem[];

    keyingDetail?: string | null;

    manufacturer: number;
    manufacturerName: string;

    supplier: number;
    supplierName: string;

    project?: number;
    projectName?: string;

    openingId: number;
    openingFloor: string | null;
    openingName: string|null;
    openingHardwareGroup: number|null;

    neededBy: DateString | null;
    releasedBy: number | null;

    inventoryRequestId: number | null;
    lastPrice: Cents | null;
    lastPriceAtOthers: Cents | null;

    doorSeries: string|null;
    doorMaterial: string|null;
    doorFinish: string|null;
    doorGauge: string|null;
    doorElevation: string|null;
    doorCore: string|null;
    doorSeam: string|null;
    doorHanding: string|null;
    doorLabel: string|null;
    doorThickness: string|null;

    frameSeries: string|null;
    frameMaterial: string|null;
    frameGauge: string|null;
    frameScreenElev: string|null;
    frameProfile: string|null;
    frameJambDepth: string|null;
    frameConstruction: string|null;
    frameHanding: string|null;
    frameHardwarePreps: string|null;
    frameLabel: string|null;
    frameType: string|null;

    div10Category:           Div10Category | null | "";
    div10Room:               string;
    div10Product:            number;
    div10Note:               string;
    div10PartitionBracing:   string;
    div10PartitionCoating:   string;
    div10LockerTiers:        string;
    div10LockerSloped:       boolean;
    div10LockerEndPanels:    boolean;
    div10LockerBaseTrim:     boolean;
    div10LockerCoating:      string;
    div10MailboxLoading:     string;
    div10MailboxMounting:    string;
    div10MailboxTenantUnits: number;
    div10MailboxParcelUnits: number;
    div10CornerGuardType:    string;
    div10CornerGuardFinish:  string;
    div10Description:        string;
}

export type PurchaseOrderItem = {
    id: number;
    purchaseOrder: number;
    reference: string;
    description: string;
    code: string;
    qty: number;

    qtyOutstanding: number;
    qtyBackOrdered: number;
    qtyPendingReceiving: number;

    logisticsQtyOutstanding: number;
    unitPriceCents: number;
    archived: boolean;
    updatedAt: DateString;
    product?: number;

    details?: DescriptionInput & {
        div10Room: string | null
    };

    kind: InventoryKind; // will never be frame-anchor b/c frame & it's frame-anchor are combined

    doorSeries?: string | null;
    doorMaterial?: string | null;
    doorFinish?: string | null;
    doorGauge?: string | null;
    doorElevation?: string | null;
    doorCore?: string | null;
    doorSeam?: string | null;
    doorHanding?: string | null;
    doorLabel?: string | null;
    doorThickness?: string|null;

    frameSeries?: string | null;
    frameMaterial?: string | null;
    frameGauge?: string | null;
    frameScreenElev?: string | null;
    frameProfile?: string | null;
    frameJambDepth?: string | null;
    frameConstruction?: string | null;
    frameHanding?: string | null;
    frameLabel?: string | null;
    frameType?: string | null;

    dimWidth?: string | null;
    dimHeight?: string | null;
    dimLength?: string | null;

    hwKeyed?: boolean | null; // applies to hardware item
    hwPrepCutFile?: number|null;
    screenElevationFile?: number|null;
    doorElevationFile?: number|null;
    hwPrepGroupNumbers?: number[];
    inventoryRequests?: number[];
    openingIds?: number[];

    openings?: {
        id: number;
        name: string;
        seqNumber: number;
        locationTransition: string; // to / from
        qty: number;
        type: string; // single/pair...
        subType?: string; // active/inactive (for the purchase order item linked, not the whole opening)
        frameHardwarePreps: POHwPrepItem[];
        doorHardwarePreps: POHwPrepItem[];
        kind: InventoryKind;
        frameAnchorProductCode?: string | null;
        frameAnchorQtyOverride?: number | null
        hardwareGroup: number;
        hasCustomActiveHingeQty?: number;
        hasCustomInActiveHingeQty?: number;
    }[];

    createStockingInventoryRequestForProduct?: number;
}

export type InventoryKind = 'hardware'|'door'|'frame'|'div-10'|'frame-anchor'|"component";

export type PurchaseOrder = {
    id: number;
    poNumber: string;
    payableRefNumber: string;
    submittedAt: DateString | null;
    due: DateString | null;
    status: string;
    payableStatus: string;
    supplier: number;
    supplierName: string;
    supplierAddress: string;
    supplierCity: string;
    supplierProvince: string;
    supplierPostal: string;
    supplierSellsFrames: boolean;
    supplierSellsDoors: boolean;
    supplierSellsHardware: boolean;
    supplierSellsDiv10: boolean;
    deliveryMethod: string;
    totalCents: number;
    updatedAt: DateString;
    updatedBy: number;
    updatedByName: string;
    notes: string;
    dateNotes: string;
    archived: boolean;
    
    linkedProjects?: {name: string; id: number}[] | null;
};

export type InventoryRequest = {
    id: number;

    // opening
    opening: number;
    openingName: string;
    locationOne: string;
    locationTransition: string;
    locationTwo: string;
    exterior: boolean;

    // div 10
    div10: number;
    room: string;
    category: string;
    manufacturer: number;
    manufacturerName: string;
    productCode: string;
    name: string;
    finish: string;
    dimensions: string;
    dimWidth: string;
    dimHeight: string;
    dimLength: string;

    isStocking: boolean;
    isNonStocking: boolean;
    hasEnoughStock: boolean;
    overrideSource?: 'non-stocking'| 'purchase'
    isWaste: boolean;
    keyed: boolean;

    qty: number;
    receivedQty?: number;
    followupRequest?: number;
    status: string;
    updatedBy: string;
    updatedByName: string;
    updatedAt: DateString;
    neededBy: string;
    neededByCalcManuallySet: string | null;
    neededByCalcTimeline: string | null;
    neededByCalcReleaseSet: string | null;

    hardwareGroup?: string;

    type: InventoryKind;
}